import React from "react"
import PropTypes from "prop-types"

import { MethodologyModel } from "../../models/Methodology.model"

import Container from "../../components/containers/Container/Container"
import LinkedSlide from "../../components/containers/LinkedSlide/LinkedSlide"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slide from "../../components/containers/Slide/Slide"
import Slider from "../../components/containers/Slider/Slider"

import styles from "./MethodologyView.module.scss"

const MethodologyView = ({ methodologyData }) => {
  if (!methodologyData) return null

  const {
    backgroundColor,
    containerPadding,
    title,
    titleSize,
    subtitle,
    slides,
    slideType,
  } = new MethodologyModel(methodologyData)

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {subtitle && <p className={styles.SubTitle}>{subtitle}</p>}
        <Slider>
          {slides.map(slide => (
            <LinkedSlide key={slide.id} slug={slide.slug}>
              <Slide {...slide} slideType={slideType} />
            </LinkedSlide>
          ))}
        </Slider>
      </Container>
    </Section>
  )
}

export default MethodologyView

MethodologyView.propTypes = {
  methodologyData: PropTypes.object.isRequired,
}
