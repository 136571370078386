import { get } from "lodash"

export class CustomersModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(this.data, "elements.section_options__background_color.value[0].name", "")
  }

  get containerPadding() {
    return get(
      this.data,
      "elements.section_options__padding.value[0].name",
      ""
    )
  }

  get customersLogos() {
    const logosData = get(
      this.data,
      "elements.customers_logos.linked_items",
      []
    )

    return logosData.map(logo => ({
      url: get(logo, "elements.logo.value[0].url"),
      description: get(logo, "elements.logo.value[0].description"),
      id: get(logo, "system.id", ""),
    }))
  }
}
