import React from "react"
import PropTypes from "prop-types"

import { TwoColumnsHeaderModel } from "../../models/TwoColumnsPageHeader.model"

import styles from "./TwoColumnsHeaderView.module.scss"
import Container from "../../components/containers/Container/Container"

const TwoColumnsHeaderView = ({ twoColumnsHeaderData }) => {
  const { backgroundImage, sideImage, text, title } = new TwoColumnsHeaderModel(
    twoColumnsHeaderData
  )

  return (
    <Container additionalClass="Medium" className={styles.SectionWrapper}>
      <div className={styles.Content}>
        <h1 className={styles.Title}>{title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className={styles.Text}
        />
      </div>
     
      <div className={styles.SideImageWrapper}>
      <div
          className={styles.BackgroundImage}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <img src={sideImage} alt="" className={styles.SideImage}/>
      </div>
    </Container>
  )
}

export default TwoColumnsHeaderView

TwoColumnsHeaderView.propTypes = {
  twoColumnsHeaderData: PropTypes.object.isRequired,
}
