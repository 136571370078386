import { get } from "lodash"

export class LandingPageChapterHeaderModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(this.data, "elements.background_color.value[0].name", "")
  }

  get chapterName() {
    return get(this.data, "elements.chapter_name.value", "")
  }

  get chapterType() {
    return get(this.data, "elements.chapter_type.value[0].name", "")
  }

  get images() {
    return get(this.data, "elements.images.value", [])
  }

  get sectionId() {
    return get(this.data, "elements.section_id.value", "")
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }
}
