import React from "react"
import PropTypes from "prop-types"

import { LandingPageHeaderModel } from "../../../../models/LandingPageHeader.model"

import Button from "../../Button/Button"
import LandingPageContainer from "../LandingPageContainer/LandingPageContainer"

import styles from "./LandingPageHeader.module.scss"

const LandingPageHeader = ({ headerData }) => {
  const { button, images, subtitle, text, title } = new LandingPageHeaderModel(
    headerData
  )

  return (
    <section className={styles.Wrapper}>
      <LandingPageContainer className={styles.Container}>
        <div className={styles.Content}>
          <div className={styles.Circle} />
          <h1 className={styles.Title}>{title}</h1>
          <div className={styles.ImagesContainer}>
            {images.map((image, index) => (
              <img
                key={image.url}
                src={image.url}
                alt=""
                className={`${styles.Image} ${
                  index === 0 ? styles.CircleImage : styles.WomanImage
                }`}
              />
            ))}
          </div>
          {subtitle && <h2 className={styles.Subtitle}>{subtitle}</h2>}
          <p className={styles.Text}>{text}</p>
        </div>
        <Button {...button} navigator={true} className={styles.Button} />
      </LandingPageContainer>
    </section>
  )
}

export default LandingPageHeader

LandingPageHeader.propTypes = {
  headerData: PropTypes.object.isRequired,
}
