import { LANGUAGES } from "./consts"

export const getDaysInMonth = month => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()

  if (!month && month !== 0) {
    month = currentDate.getMonth()
  }

  return new Date(year, month + 1, 0).getDate()
}

export const getHoursRange = (min, max, languge) => {
  const hoursRange = Array.from({ length: max - min + 1 }, (_, i) => i + min)

  return hoursRange.map(hour => {
    if (languge.toLowerCase() === LANGUAGES.PL) {
      return `${hour}:00`
    }

    return hour < 12 ? `${hour} AM` : `${hour === 12 ? hour : hour - 12} PM`
  })
}