import { get } from "lodash"

export class LandingPageVideoSectionModel {
  constructor(data) {
    this.data = data
  }

  get video() {
    return get(this.data, "elements.video.value", "")
  }

  get description() {
    return get(this.data, "elements.description.value", "")
  }
}
