import { get } from 'lodash'

export class CardModel {
  constructor(data) {
    this.data = data
  }

  get title() {
    return get(this.data, 'elements.title.value')
  }

  get subtitle() {
    return get(this.data, 'elements.subtitle.value')
  }

  get description() {
    return get(this.data, 'elements.description.value')
  }

  get image() {
    return get(this.data, 'elements.image.value[0].url')
  }

  getCard() {
    return {
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      image: this.image
    }
  }
}