import { get } from "lodash"
import { ButtonModel } from "./Button.model"

export class FormModel {
  constructor(data) {
    this.data = data
  }

  get id() {
    return get(this.data, "system.id", "")
  }

  get type() {
    return get(this.data, "system.type", "")
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, image, name, size } = new ButtonModel(buttonData)

    return {
      color,
      image,
      name,
      size,
    }
  }

  get companySizes() {
    return get(this.data, "elements.company_sizes.value", [])
  }

  get dateInputDayPlaceholder() {
    return get(this.data, "elements.date_input_day_placeholder.value", "")
  }

  get dateInputHourPlaceholder() {
    return get(this.data, "elements.date_input_hour_placeholder.value", "")
  }

  get dateInputMonthPlaceholder() {
    return get(this.data, "elements.date_input_month_placeholder.value", "")
  }

  get dateInputsTitle() {
    return get(this.data, "elements.date_inputs_title.value", "")
  }

  get mainInputPlaceholder() {
    return get(this.data, "elements.main_input_placeholder.value", "")
  }

  get maxMeetingHour() {
    return get(this.data, "elements.max_meeting_hour.value", "")
  }

  get minMeetingHour() {
    return get(this.data, "elements.min_meeting_hour.value", "")
  }

  get marketingAgreementCheckbox() {
    return get(this.data, "elements.marketing_agreement_checkbox.value", "")
  }

  get privacyPolicyAgreementCheckbox() {
    return get(
      this.data,
      "elements.privacy_policy_agreement_checkbox.value",
      ""
    )
  }

  get selectInputPlaceholder() {
    return get(this.data, "elements.select_input_placeholder.value", "")
  }

  get textAreaInputPlaceholder() {
    return get(this.data, "elements.textarea_input_placeholder.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get captchaErrorMessage() {
    return get(this.data, "elements.captcha_error_message.value", "")
  }

  get useCaptcha() {
    return get(this.data, "elements.use_captcha.value[0].codename") === "true"
  }

  get sendingMessage() {
    return get(this.data, "elements.sending_message.value", "")
  }

  get successMessage() {
    return get(this.data, "elements.success_message.value", "")
  }

  get errorMessage() {
    return get(this.data, "elements.error_message.value", "")
  }
}
