import React from "react"
import PropTypes from "prop-types"

import { KENTICO_CONTENT_TYPES } from "../../../utils/consts"

import AccordionView from "../../../views/AccordionView/AccordionView"
import ContactSectionView from "../../../views/ContactSectionView/ContactSectionView"
import CustomersReferencesView from "../../../views/CustomersReferencesView/CustomersReferencesView"
import CustomersView from "../../../views/CustomersView/CustomersView"
import HeaderView from "../../../views/HeaderView/HeaderView"
import HowItWorksSectionView from "../../../views/HowItWorksSectionView/HowItWorksSectionView"
import LandingPageChapterHeader from "../../blocks/LP/LandingPageChapterHeader/LandingPageChapterHeader"
import LandingPageHeader from "../../blocks/LP/LandingPageHeader/LandingPageHeader"
import LandingPageImagesRow from "../../blocks/LP/LandingPageImagesRow/LandingPageImagesRow"
import LandingPageNavigator from "../../blocks/LP/LandingPageNavigator/LandingPageNavigator"
import LandingPageSection from "../../blocks/LP/LandingPageSection/LandingPageSection"
import LandingPageTestimonialSection from "../../blocks/LP/LandingPageTestimonialSection/LandingPageTestimonialSection"
import LandingPageTwoColumnsSection from "../../blocks/LP/LandingPageTwoColumnsSection/LandingPageTwoColumnsSection"
import LandingPageVideoSection from "../../blocks/LP/LandingPageVideoSection/LandingPageVideoSection"
import MethodologyView from "../../../views/MethodologyView/MethodologyView"
import Navigator from "../../blocks/Navigator/Navigator"
import NumbersSectionView from "../../../views/NumbersSectionView/NumbersSectionView"
import OneColumnView from "../../../views/OneColumnView/OneColumnView"
import OneColumnWithSliderView from "../../../views/OneColumnWithSliderView/OneColumnWithSliderView"
import OurTeamView from "../../../views/OurTeamView/OurTeamView"
import RichText from "../../blocks/RichText/RichText"
import TwoColumnsHeaderView from "../../../views/TwoColumnsHeaderView/TwoColumnsHeaderView"
import TwoColumnsListView from "../../../views/TwoColumnsListView/TwoColumnsListView"
import TwoColumnsWithSliderView from "../../../views/TwoColumnsWithSliderView/TwoColumnsWithSliderView"
import TwoColumnsView from "../../../views/TwoColumnsView/TwoColumnsView"
import LandingPageParagraphSection from "../../blocks/LP/LandingPageParagraphSection/LandingPageParagraphSection"
import TwoColumnsWithTitleView from "../../../views/TwoColumnsWithTitleView/TwoColumnsWithTitleView"
import ThreeFourColumnSectionView from "../../../views/ThreeFourColumnSectionView/ThreeFourColumnSectionView"
import DownloadSection from "../DownloadSection/DownloadSection"

const PageContentParser = ({ contentData }) => {
  return (
    <>
      {contentData.map(contentItem => {
        if (!contentItem.system || !contentItem.system.type) return null
        switch (contentItem.system.type) {
          case KENTICO_CONTENT_TYPES.ACCORDION_SECTION:
            return (
              <AccordionView
                key={contentItem.system.id}
                accordionSectionData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.CONTACT_SECTION:
            return (
              <ContactSectionView
                key={contentItem.system.id}
                contactData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.CUSTOMERS_REFERENCES_SECTION:
            return (
              <CustomersReferencesView
                key={contentItem.system.id}
                customersReferencesData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.CUSTOMERS_SECTION:
            return (
              <CustomersView
                key={contentItem.system.id}
                customersData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.THREE_FOUR_COLUMN_SECTION:
            return (
              <ThreeFourColumnSectionView
                key={contentItem.system.id}
                threefourColumnsData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.HOW_IT_WORKS_SECTION:
            return (
              <HowItWorksSectionView
                key={contentItem.system.id}
                howItWorksData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_CHAPTER_HEADER:
            return (
              <LandingPageChapterHeader
                key={contentItem.system.id}
                chapterHeaderData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_HEADER:
            return (
              <LandingPageHeader
                key={contentItem.system.id}
                headerData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_IMAGES_ROW:
            return (
              <LandingPageImagesRow
                key={contentItem.system.id}
                imagesRowData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_NAVIGATOR:
            return (
              <LandingPageNavigator
                key={contentItem.system.id}
                navigatorData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_SECTION:
            return (
              <LandingPageSection
                key={contentItem.system.id}
                sectionData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_TESTIMONIAL_SECTION:
            return (
              <LandingPageTestimonialSection
                key={contentItem.system.id}
                testimonialData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_TWO_COLUMNS_SECTION:
            return (
              <LandingPageTwoColumnsSection
                key={contentItem.system.id}
                twoColumnsData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDIGN_PAGE_VIDEO_SECTION:
            return (
              <LandingPageVideoSection
                key={contentItem.system.id}
                videoSectionData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.METHODOLOGY_SECTION:
            return (
              <MethodologyView
                key={contentItem.system.id}
                methodologyData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.NUMBERS_SECTION:
            return (
              <NumbersSectionView
                key={contentItem.system.id}
                numbersSectionData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.ONE_COLUMN_SECTION:
            return (
              <OneColumnView
                key={contentItem.system.id}
                oneColumnData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.ONE_COLUMN_WITH_SLIDER:
            return (
              <OneColumnWithSliderView
                key={contentItem.system.id}
                oneColumnWithSliderData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.OUR_TEAM_SECTION:
            return (
              <OurTeamView
                key={contentItem.system.id}
                ourTeamData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.PAGE_HEADER:
            return (
              <HeaderView
                key={contentItem.system.id}
                headerData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.TWO_COLUMNS_HEADER:
            return (
              <TwoColumnsHeaderView
                key={contentItem.system.id}
                twoColumnsHeaderData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.TWO_COLUMNS_LIST:
            return (
              <TwoColumnsListView
                key={contentItem.system.id}
                twoColumnsListData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.TWO_COLUMNS_WITH_SLIDER:
            return (
              <TwoColumnsWithSliderView
                key={contentItem.system.id}
                twoColumnsWithSliderData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.TWO_COLUMN_SECTION:
            return (
              <TwoColumnsView
                key={contentItem.system.id}
                twoColumnsData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.LANDING_PAGE_PARAGRAPH_SECTION:
            return (
              <LandingPageParagraphSection
                key={contentItem.system.id}
                paragraphSectionData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.TWO_COLUMNS_WITH_TITLE:
            return (
              <TwoColumnsWithTitleView
                key={contentItem.system.id}
                twoColumnsWithTitleData={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.RICH_TEXT:
            return (
              <RichText
                key={contentItem.system.id}
                richText={contentItem}
              />
            )
          case KENTICO_CONTENT_TYPES.NAVIGATOR: 
              return (
                <Navigator
                  key={contentItem.system.id}
                  navigator={contentItem}
                />
              )
          case KENTICO_CONTENT_TYPES.DOWNLOAD_SECTION:
            return (
              <DownloadSection
                key={contentItem.system.id}
                downloadSection={contentItem}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default PageContentParser

PageContentParser.propTypes = {
  contentData: PropTypes.array.isRequired,
}
