import React from "react"
import PropTypes from "prop-types"

import Header from "../../../components/blocks/Header/Header"
import DownloadItem from '../../../components/blocks/DownloadItem/DownloadItem'

import styles from "./DownloadSection.module.scss"

import { DownloadSectionModel } from "../../../models/DownloadSection.model";


const DownloadSection = ({ downloadSection }) => {
    if (!downloadSection) return null
    
    const {header, downloadItems} = new DownloadSectionModel(downloadSection);

    return (
        <div className={styles.FlexWrapper}>
        <div className={styles.RecomendationContainer}>
          <Header>{header}</Header>
          <div>
            {downloadItems.map(
              item => (
                <DownloadItem {...item} />
              )
            )}
          </div>
        </div>
      </div>
    )
}

export default DownloadSection

DownloadSection.propTypes = {
    downloadSection: PropTypes.object.isRequired,
}