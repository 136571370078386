import { get } from 'lodash'

export class AccordionModel {
  constructor(data) {
    this.data = data
  }

  get title() {
    return get(this.data, 'elements.title.value')
  }

  get text() {
    return get(this.data, 'elements.text.value')
  }
}