import React from "react"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll';

import LocalizedLink from "../LocalizedLink/LocalizedLink"

import styles from "./Button.module.scss"

const Button = ({ className = '', color, image, name, navigator = false, size, slug, openInNewTab = false }) => {
  const colorClass = styles[color] || ""
  const sizeClass = image ? "" : styles[size] || ""
  const buttonWithImageClass = image ? styles.ImageButton : styles.Button
  navigator = !!slug && !slug.includes('/')

  if(navigator) {
    if (slug.startsWith('mailto'))    
    return (
      <a
        href={`${slug}`}
        className={`${buttonWithImageClass} ${colorClass} ${sizeClass} ${className}`}
      >
        <span>{name}</span>
        {image && (
          <span
            className={styles.ButtonImage}
            style={{ backgroundImage: `url(${image})` }}
          />
        )}
      </a>
    )

    return (
      <button
        className={`${buttonWithImageClass} ${colorClass} ${sizeClass} ${className}`}
        onClick={() => scrollTo(`#${slug}`)}
      >
        <span>{name}</span>
        {image && (
          <span
            className={styles.ButtonImage}
            style={{ backgroundImage: `url(${image})` }}
          />
        )}
      </button>
    )
  }

  if (slug) {
    if (openInNewTab) {
      return(
        <a
          href={slug} target="_blank" rel="noopener noreferrer"
          className={`${buttonWithImageClass} ${colorClass} ${sizeClass} ${className}`}
        >
          <span>{name}</span>
          {image && (
           <span
              className={styles.ButtonImage}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </a>
      )
    }

    return (
      <LocalizedLink
        to={slug}
        className={`${buttonWithImageClass} ${colorClass} ${sizeClass} ${className}`}
      >
        {name}
        {image && (
          <span
            className={styles.ButtonImage}
            style={{ backgroundImage: `url(${image})` }}
          />
        )}
      </LocalizedLink>
    )
  }

  return (
    <button
      className={`${buttonWithImageClass} ${colorClass} ${sizeClass} ${className}`}
    >
      <span>{name}</span>
      {image && (
        <span
          className={styles.ButtonImage}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
    </button>
  )
}

export default Button

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  navigator: PropTypes.bool,
  size: PropTypes.string,
  slug: PropTypes.string,
}
