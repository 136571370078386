import React from "react"
import PropTypes from "prop-types"

import { CustomersModel } from "../../models/Customers.model"

import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import Slider from "../../components/containers/Slider/Slider"

import styles from "./CustomersView.module.scss"

const CustomersView = ({ customersData }) => {
  if (!customersData) return null

  const {
    backgroundColor,
    containerPadding,
    customersLogos,
  } = new CustomersModel(customersData)

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <Slider marginBottom={0}>
          {customersLogos.map(logo => (
            <img
              key={logo.id}
              src={logo.url}
              alt={logo.alt || ""}
              className={styles.Logo}
            />
          ))}
        </Slider>
      </Container>
    </Section>
  )
}

export default CustomersView

CustomersView.propTypes = {
  customersData: PropTypes.object.isRequired,
}
