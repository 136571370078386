import axios from "axios"
import getCountryName from "../utils/country";

const mailServiceURL =
  "https://prod-75.westeurope.logic.azure.com:443/workflows/5ae7a1eaf96241c6b0e3b274fea13610/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=330sJtfi5PZbksDJwVjtG27Wv8AqOna4vc2BKtyYOhM"
const pipdriveURL = 
  "https://thg-d-www-pipedrive-fap.azurewebsites.net/api/postnote?code=Ils3YzEnC4e_eucIqh-bmAFyZfiILKHctzHGfhApQ46-AzFun4AhSA%3D%3D"
  
class MailingService {
  static async sendMail(pipdrive, mail) {
    const inbound = typeof window !== 'undefined' ? window.localStorage.getItem('inbound') : '';
    const country = getCountryName(pipdrive.country);

    const pipdriveData = {
      name: pipdrive.contactPerson,
      content: pipdrive,
      person: {
        name: pipdrive.email.split('@')[0].replace(/_-./g, ' '),
        email: pipdrive.email,
        country: country,
        marketingAgreement: pipdrive.marketingAgreement,
      },
      marketingAgreement: pipdrive.marketingAgreement || false,
      inbound: inbound || "Inbound - Unidentified",
      businessUnit:"GrowUperion",
    }

    try {
      await axios.post(mailServiceURL, { ...mail })
      axios.post(pipdriveURL, pipdriveData)
      return true
    } catch (e) {
      return false
    }
  }
}

export default MailingService
