import { get } from "lodash"

export class TwoColumnsHeaderModel {
  constructor(data) {
    this.data = data
  }

  get backgroundImage() {
    return get(this.data, "elements.background_image.value[0].url", "")
  }

  get sideImage() {
    return get(this.data, "elements.side_image.value[0].url", "")
  }

  get text() {
    return get(this.data, "elements.text.resolvedData.html", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }
}
