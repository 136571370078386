import React from "react"
import PropTypes from "prop-types"

import { OneColumnModel } from "../../models/OneColumn.model"

import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"

import styles from "./OneColumnView.module.scss"

const OneColumnView = ({ oneColumnData }) => {
  if (!oneColumnData) return null

  const {
    backgroundColor,
    button,
    containerPadding,
    subtitle,
    title,
    titleSize,
  } = new OneColumnModel(oneColumnData)

  //TODO: implement classes for containerPadding

  return (
    <Section backgroundColor={backgroundColor}>
      <Container 
        className={styles.Container} 
        additionalClass={containerPadding}
      > 
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
        <Button {...button} />
      </Container>
    </Section>
  )
}

export default OneColumnView

OneColumnView.propTypes = {
  oneColumnData: PropTypes.object.isRequired,
}
