import React from "react"
import PropTypes from "prop-types"

import styles from "./ServiceLineSlide.module.scss"

const ServiceLineSlide = ({ image, title, subtitle }) => {
  return (
    <div className={styles.Slide}>
      <div
        className={styles.SlideImage}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <p className={styles.SlideTitle}>{title}</p>
      <p className={styles.SlideSubtitle}>{subtitle}</p>
    </div>
  )
}

export default ServiceLineSlide

ServiceLineSlide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}
