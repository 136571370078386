import React from "react"
import PropTypes from "prop-types"

import { LandingPageImagesRowModel } from "../../../../models/LandingPageImagesRow.model"

import styles from "./LandingPageImagesRow.module.scss"

const LandingPageImagesRow = ({ imagesRowData }) => {
  const { images } = new LandingPageImagesRowModel(imagesRowData)

  return (
    <div className={styles.ImagesContainer}>
      {images.map(image => (
        <img key={image.url} src={image.url} alt={image.description || ""} />
      ))}
    </div>
  )
}

export default LandingPageImagesRow

LandingPageImagesRow.propTypes = {
  imagesRowData: PropTypes.object.isRequired,
}
