import { get } from "lodash"
import { ButtonModel } from "./Button.model"

export class ColumnGamificationSectionModel{
    constructor(data) {
        this.data = data
    }
    get button() {
        const buttonData = get(this.data, "elements.button.linked_items[0]", {})
    
        const { color, name, size, slug } = new ButtonModel(buttonData)
    
        return {
            color,
            name,
            size,
            slug,
        }
    }
    get imageUrl() {
        return get(this.data, "elements.image.value[0].url", "")
    }

    get subtitle() {
        return get(this.data, "elements.subtitle.value", "")
    }

    get title() {
        return get(this.data, "elements.title.value", "")
    }
    get titleSize() {
        return get(
            this.data,
            "elements.section_options__title_size.value[0].name",
            ""
        )
    }

    get backgroundColor() {
        return get(
            this.data,
            "elements.section_options__background_color.value.name",
            ""
        )
    }

    get containerPadding() {
        return get(this.data, "elements.section_options__padding.value[0].name", "")
    }
}