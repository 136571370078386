import React from "react";
import PropTypes from "prop-types"

import { TwoColumnsWithTitleModel } from "../../models/TwoColumnsWithTitle.model";

import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"

import styles from "./TwoColumnsWithTitleView.module.scss"
import ColummWithImageComponent from "../../components/blocks/ColumnWithImageComponent/ColumnWithImageComponent";

const TwoColumnsWithTitleView = ({ twoColumnsWithTitleData }) => {
    // const { isMobile, isTablet, isDesktop } = useIsMobile()
    if (!twoColumnsWithTitleData) return null

    const {
        title,
        text,
        titleSize,
        backgroundColor,
        gamificationPlatformColumn,
        gamificationOnbordingColumn

    } = new TwoColumnsWithTitleModel(twoColumnsWithTitleData);

    return (
        <div>
            <Section backgroundColor={backgroundColor}>
                <div className={styles.Container}>
                    <SectionTitle
                        className={styles.Title}
                        title={title}
                        titleSize={titleSize}
                    />
                    {text && <p className={styles.Text}>{text}</p>}
                    <div className={styles.ColumnsContainer}>
                        <ColummWithImageComponent {...gamificationPlatformColumn} />
                        <ColummWithImageComponent {...gamificationOnbordingColumn} />
                    </div>
                </div>
            </Section>
        </div>)
}
export default TwoColumnsWithTitleView;

TwoColumnsWithTitleView.propTypes = {
    twoColumnsWithTitleData: PropTypes.object.isRequired,
}