import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { TwoColumnsWithSliderModel } from "../../models/TwoColumnsWithSlider.model"

import Container from "../../components/containers/Container/Container"
import LinkedSlide from "../../components/containers/LinkedSlide/LinkedSlide"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slide from "../../components/containers/Slide/Slide"
import Slider from "../../components/containers/Slider/Slider"

import styles from "./TwoColumnsWithSliderView.module.scss"

const TwoColumnsWithSliderView = ({ twoColumnsWithSliderData }) => {
  const [windowWidth, setWindowWidth] = useState(undefined)
  const isMobile = windowWidth <= 650

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
      document.body.classList.remove("menu-open")
    }
  }, [])

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  if (!twoColumnsWithSliderData) return null

  const {
    backgroundColor,
    containerPadding,
    image,
    imageMobilePosition,
    imageSide,
    slides,
    slideType,
    subtitle,
    title,
    titleSize,
    video_url
  } = new TwoColumnsWithSliderModel(twoColumnsWithSliderData)

  const imagePositionClass = imageMobilePosition
    ? styles[imageMobilePosition]
    : styles.Left
  const imageSideClass = imageSide ? styles[imageSide] : styles.Top

  const slidesComponent = slides.map(slide => (
    <LinkedSlide key={slide.id} slug={slide.slug}>
      <Slide {...slide} slideType={slideType} />
    </LinkedSlide>
  ))

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={`${styles.SectionWrapper} ${imagePositionClass}`}
        additionalClass={containerPadding}
      >
        {video_url ? 
          ( <iframe className={styles.Video} src={video_url} frameBorder="0" title={title}/> ) :
          ( <img src={image} className={`${styles.Image} ${imageSideClass}`} alt="" /> )
        }
        <div className={styles.Content}>
          <SectionTitle
            className={styles.Title}
            title={title}
            titleSize={titleSize}
          />
          {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
          {isMobile ? (
            <Slider>{slidesComponent}</Slider>
          ) : (
            <div className={styles.GridContainer}>{slidesComponent}</div>
          )}
        </div>
      </Container>
    </Section>
  )
}

export default TwoColumnsWithSliderView

TwoColumnsWithSliderView.propTypes = {
  twoColumnsWithSliderData: PropTypes.object.isRequired,
}
