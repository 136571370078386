import React from "react"
import PropTypes from "prop-types"

import styles from "./IconSlide.module.scss"

const IconSlide = ({ alt, image, subtitle, title }) => {
  return (
    <div className={styles.Slide}>
      <img className={styles.SlideImage} src={image} alt={alt || ""} />
      <p className={styles.SlideTitle}>{title}</p>
      <p className={styles.SlideSubtitle}>{subtitle}</p>
    </div>
  )
}

export default IconSlide

IconSlide.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
