import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import styles from "./Slider.module.scss"

const Slider = ({ children, className = '', marginBottom }) => {
  const slidesCount = children.length;
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [lastPosition, setLastPosition] = useState(0)
  const [move, setMove] = useState(0)

  const sliderRef = useRef(null)

  const handleTouchStart = e => {
    setIsMouseDown(true)
    setStartX(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = e => {
    setIsMouseDown(false);
    setLastPosition(move)
  }

  const handleTouchMove = e => {
    if(!isMouseDown) return;

    const transformedBy = startX - e.targetTouches[0].clientX;

    if(lastPosition + transformedBy * -1 >= 100 || lastPosition + transformedBy * -1 <= (sliderRef.current.scrollWidth - sliderRef.current.clientWidth) * -1 - 100) {
      return;
    }
    
    setMove(lastPosition + transformedBy * -1)
  }

  const handleMouseDown = e => {
    setIsMouseDown(true)
    setStartX(e.pageX)
  }

  const handleMouseLeave = () => {
    setIsMouseDown(false)
    setLastPosition(move)
  }

  const handleMouseUp = e => {
    setIsMouseDown(false)
    setLastPosition(move)
  }

  const handleMouseMove = e => {
    if (!isMouseDown) return
    e.preventDefault()

    const transformedBy = startX - e.pageX;

    if(lastPosition + transformedBy * -1 >= 0 || lastPosition + transformedBy * -1 <= (sliderRef.current.scrollWidth - sliderRef.current.clientWidth) * -1) {
      return;
    }
    
    setMove(lastPosition + transformedBy * -1)
  }

  const alignmentClass = slidesCount > 4 ? styles.FlexStart : '';

  return (
  
    <div
      className={`${styles.Slider} ${className}`}
      style={{ marginBottom: `${marginBottom}px`, transform: `translateX(${move}px)`}}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={`${styles.Box} ${alignmentClass}`}>

      {children}
      </div>
    </div>
 
  )
}

export default Slider

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
}
