import { get } from "lodash"
import { ButtonModel } from "./Button.model"

export class TwoColumnsModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, image, name, size, slug } = new ButtonModel(buttonData)

    return {
      color,
      image,
      name,
      size,
      slug,
    }
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get image() {
    return get(this.data, "elements.image.value[0].url", "")
  }

  get imageMobilePosition() {
    return get(
      this.data,
      "elements.image_mobile_position__position.value[0].name",
      ""
    )
  }

  get imageSide() {
    return get(this.data, "elements.image_side__side.value[0].name", "")
  }

  get textBig() {
    return get(this.data, "elements.text_big.value", "")
  }

  get textSmall() {
    return get(this.data, "elements.text_small.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }
}
