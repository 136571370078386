import React from "react"
import PropTypes from "prop-types"

import { AccordionViewModel } from '../../models/AccordionView.model'
import Accordion from "../../components/blocks/Accordion/Accordion"
import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Section from "../../components/containers/Section/Section"

import styles from "./AccordionView.module.scss"

const AccordionView = ({ accordionSectionData }) => {
  if (!accordionSectionData) return null

  const {
    title,
    subtitle,
    titleSize,
    containerPadding,
    backgroundColor,
    image,
    imageMobilePosition,
    imageSide,
    button,
    accordionItems
  } = new AccordionViewModel(accordionSectionData)

  const imagePositionClass = imageMobilePosition
    ? styles[imageMobilePosition]
    : styles.Left
  const imageSideClass = imageSide ? styles[imageSide] : styles.Top

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={`${styles.SectionWrapper} ${imagePositionClass} ${imageSide}`}
        additionalClass={containerPadding}
      >
        <div className={`${styles.ImageWrapper} ${imageSideClass}`}>
          <img
            src={image}
            className={`${styles.Image} ${imageSideClass}`}
            alt=""
          />
        </div>
        <div className={styles.Content}>
          <SectionTitle
            className={styles.Title}
            title={title}
            titleSize={titleSize}
          />
          {subtitle && <p className={styles.TextBig}>{subtitle}</p>}
          <Accordion className={styles.Accordion} accordionItems={accordionItems} />
          {button.name && <Button {...button} />}
        </div>
      </Container>
    </Section>
  )
}

export default AccordionView

AccordionView.propTypes = {
  accordionSectionData: PropTypes.object.isRequired,
}
