import React from "react"
import PropTypes from "prop-types"
import AccordionItem from "../Accordion/AccordionItem"
import styles from './Accordion.module.scss'

const Accordion = ({ accordionItems, className = '' }) => {
  return (
    <div className={`${className}`}>
      <dl>
        {accordionItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <AccordionItem
                title={item.title}
                text={item.text}
              />
              <div className={styles.Separator}></div>
            </React.Fragment>
          )
        })}
      </dl>
    </div>
  )
}

export default Accordion

Accordion.propTypes = {
  accordionData: PropTypes.array,
  className: PropTypes.string
}
