import React from "react"
import PropType from "prop-types"

const SelectInput = ({
  className,
  onChange,
  name,
  optionsValue,
  placeholder,
  required,
  value,
}) => {
  return (
    <select
      className={className}
      value={value}
      onChange={onChange}
      name={name}
      required={required}
    >
      <option value={""}>{placeholder}</option>
      {optionsValue.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default SelectInput

SelectInput.propTypes = {
  className: PropType.string,
  onChange: PropType.func.isRequired,
  name: PropType.string.isRequired,
  optionsValue: PropType.array.isRequired,
  required: PropType.bool,
  placeholder: PropType.string.isRequired,
  value: PropType.string.isRequired,
}
