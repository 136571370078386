import React from "react"
import PropTypes from "prop-types"

import { NavigatorModel } from "../../../models/Navigator.model";

const Navigator = ({ navigator }) => {
    if (!navigator) return null

    const { to } = new NavigatorModel(navigator)
    
    return (
        <div id={to}></div>
    )
}

export default Navigator

Navigator.propTypes = {
    navigator: PropTypes.object.isRequired,
}