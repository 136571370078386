import React from "react"
import PropTypes from "prop-types"

import { OurTeamModel } from "../../models/OurTeam.model"

import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slide from "../../components/containers/Slide/Slide"
import SliderWithButtons from "../../components/containers/SliderWithButtons/SliderWithButtons"

import styles from "./OurTeamView.module.scss"

const OurTeamView = ({ ourTeamData }) => {
  if (!ourTeamData) return null

  const {
    containerPadding,
    backgroundColor,
    slides,
    slideType,
    title,
    titleSize,
    buttons
  } = new OurTeamModel(ourTeamData)

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        <SliderWithButtons buttons={buttons}>
          {slides.map(slide => (
            <Slide {...slide} slideType={slideType} />
          ))}
        </SliderWithButtons>
      </Container>
    </Section>
  )
}

export default OurTeamView

OurTeamView.propTypes = {
  ourTeamData: PropTypes.object.isRequired,
}
