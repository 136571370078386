import React from "react"
import PropTypes from "prop-types"

import { RichTextModel } from "../../../models/RichText.model";
import styles from "./RichText.module.scss"


const RichText = ({ richText }) => {
    if (!richText) return null

    const { title, text } = new RichTextModel(richText)
    return (
        <div className={styles.RichTextSection}>
            <h1 className={styles.RichTextTitle}>{title}</h1>
            <div className={styles.RichTextContent} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    )
}

export default RichText

RichText.propTypes = {
    richText: PropTypes.object.isRequired,
}