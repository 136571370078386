import React from "react"
import PropTypes from "prop-types"

import styles from "./LandingPageVideoSection.module.scss"
import { LandingPageVideoSectionModel } from "../../../../models/LandingPageVideoSection.model"

const LandingPageVideoSection = ({ videoSectionData }) => {
  const { description, video } = new LandingPageVideoSectionModel(
    videoSectionData
  )

  return (
    <div className={styles.Container}>
      <div
        dangerouslySetInnerHTML={{ __html: video }}
        className={styles.VideoContainer}
      />
      <p>{description}</p>
    </div>
  )
}

export default LandingPageVideoSection

LandingPageVideoSection.propTypes = {
  videoSectionData: PropTypes.object.isRequired,
}
