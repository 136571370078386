import { get } from "lodash"
import { ContactPersonModel } from "./ContactPerson.model"
import { FormModel } from "./Form.model"

export class ContactModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get contactPerson() {
    const contactPersonData = get(
      this.data,
      "elements.contact_person.linked_items[0]"
    )

    const {
      alt,
      image,
      email,
      nameAndSurname,
      phoneNumber,
    } = new ContactPersonModel(contactPersonData)

    return {
      alt,
      image,
      email,
      nameAndSurname,
      phoneNumber,
    }
  }

  get forms() {
    const formsData = get(this.data, "elements.form.linked_items", [])

    return formsData.map(form => {
      const {
        id,
        type,
        button,
        companySizes,
        dateInputsTitle,
        dateInputDayPlaceholder,
        dateInputHourPlaceholder,
        dateInputMonthPlaceholder,
        maxMeetingHour,
        minMeetingHour,
        marketingAgreementCheckbox,
        mainInputPlaceholder,
        privacyPolicyAgreementCheckbox,
        selectInputPlaceholder,
        textAreaInputPlaceholder,
        title,
        captchaErrorMessage,
        useCaptcha,
        sendingMessage,
        successMessage,
        errorMessage
      } = new FormModel(form)

      return {
        id,
        type,
        button,
        companySizes,
        dateInputsTitle,
        dateInputDayPlaceholder,
        dateInputHourPlaceholder,
        dateInputMonthPlaceholder,
        maxMeetingHour,
        minMeetingHour,
        marketingAgreementCheckbox,
        mainInputPlaceholder,
        privacyPolicyAgreementCheckbox,
        selectInputPlaceholder,
        textAreaInputPlaceholder,
        title,
        captchaErrorMessage,
        useCaptcha,
        sendingMessage,
        successMessage,
        errorMessage
      }
    })
  }

  get image() {
    return get(this.data, "elements.image.value[0].url", "")
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }
}
