import React from "react"
import PropTypes from "prop-types"

import styles from "./SectionTitle.module.scss"

const SectionTitle = ({ className, title, titleSize }) => {
  const titleSizeClass = titleSize ? styles[titleSize] : ""

  return (
    <>
      {title && (
        <div className={`${styles.Title} ${className} ${titleSizeClass}`} dangerouslySetInnerHTML={{ __html: title }} />
      )}
    </>
  )
}

export default SectionTitle

SectionTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
}
