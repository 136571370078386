import { get } from "lodash"
import { ButtonModel } from "./Button.model"

export class LandingPageHeaderModel {
  constructor(data) {
    this.data = data
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]")

    const { color, image, name, size, slug } = new ButtonModel(buttonData)

    return { color, image, name, size, slug }
  }

  get images() {
    return get(this.data, "elements.images.value", [])
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get text() {
    return get(this.data, "elements.text.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }
}
