import React from "react"
import PropTypes from "prop-types"

import { LandingPageTestimonialModel } from "../../../../models/LandingPageTestimonialSection.model"

import styles from "./LandingPageTestimonialSection.module.scss"

const LandingPageTestimonialSection = ({ testimonialData }) => {
  const { icons, image, text } = new LandingPageTestimonialModel(
    testimonialData
  )

  const getClass = index => {
    switch (index) {
      case 0:
        return styles.TopLeft
      case 1:
        return styles.TopRight
      case 2:
        return styles.BottomRight
      case 3:
        return styles.BottomLeft
      default:
        return ""
    }
  }

  return (
    <section>
      <div className={styles.Container}>
        <div className={styles.ContentContainer}>
          <div className={styles.ImageContainer}>
            <img
              src={image.url}
              alt={image.description}
              className={styles.Image}
            />
            {image.description && (
              <p className={styles.ImageDescription}>{image.description}</p>
            )}
            {icons.map((icon, index) => (
              <img
                key={icon.url + index}
                src={icon.url}
                alt=""
                className={`${styles.Icon} ${getClass(index)}`}
              />
            ))}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className={styles.Text}
          />
        </div>
      </div>
    </section>
  )
}

export default LandingPageTestimonialSection

LandingPageTestimonialSection.propTypes = {
  testimonialData: PropTypes.object.isRequired,
}
