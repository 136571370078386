import React from "react"
import PropTypes from "prop-types"
import MinusIcon from '../../../assets/minusIcon.svg'
import PlusIcon from '../../../assets/plusIcon.svg'
import styles from './PlusMinusIcon.module.scss'

const PlusMinusIcon = ({ active }) => {
  return (
    <div className={styles.PlusMinusIcon}>
      <PlusIcon className={`${styles.Plus} ${!active && styles.Active}`} />
      <MinusIcon className={`${styles.Minus} ${active && styles.Active}`} />
    </div>
  )
}

export default PlusMinusIcon

PlusMinusIcon.propTypes = {
  active: PropTypes.bool
}