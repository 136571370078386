import React from "react"
import PropTypes from "prop-types"

import { LandingPageTwoColumnsSectionModel } from "../../../../models/LandingPageTwoColumnsSection.model"

import styles from "./LandingPageTwoColumnsSection.module.scss"

const LandingPageTwoColumnsSection = ({ twoColumnsData }) => {
  const { image, text } = new LandingPageTwoColumnsSectionModel(twoColumnsData)

  return (
    <div className={styles.Wrapper}>
      <img
        src={image.url}
        alt={image.description || ""}
        className={styles.Image}
      />
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default LandingPageTwoColumnsSection

LandingPageTwoColumnsSection.propTypes = {
  twoColumnsData: PropTypes.object.isRequired,
}
