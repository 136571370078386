import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Loader from "react-loader-spinner"
import ReCAPTCHA from "react-google-recaptcha"

import { LangContext } from "../../../context/LangContext"
import MailingService from "../../../service/MailingService"

import { MONTHS } from "../../../utils/consts"
import { getHoursRange } from "../../../utils/utils"

import Button from "../Button/Button"
import Checkbox from "../Checkbox/Checkbox"
import SelectInput from "../SelectInput/SelectInput"

import styles from "./DateForm.module.scss"

const formInitialValues = {
  email: "",
  companySize: "",
  hour: "",
  day: "",
  month: "",
}

const checkboxesInitialValues = {
  privacyAgreement: false,
  marketingAgreement: false,
}

const DateForm = ({
  button,
  companySizes,
  dateInputsTitle,
  dateInputHourPlaceholder,
  maxMeetingHour,
  minMeetingHour,
  marketingAgreementCheckbox,
  isActive,
  mainInputPlaceholder,
  privacyPolicyAgreementCheckbox,
  selectInputPlaceholder,
  captchaErrorMessage,
  useCaptcha,
  contactPerson,
  sendingMessage,
  successMessage,
  errorMessage
}) => {
  const [showErrorCaptcha, setShowErrorCaptcha] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [checkboxesValues, setCheckboxesValues] = useState(
    checkboxesInitialValues
  )
  const [isFormValid, setIsFormValid] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const { pageLang } = useContext(LangContext)
  const [loading, setLoading] = useState(false)
  const [clientIP, setClientIP] = useState('')
  const [country, setCountry] = useState('PL')
  const [message, setMessage] = useState('')

  React.useEffect(() => {
    fetch('https://ipinfo.io/json?token=ea4d0cf5569d7d')
      .then(res => res.json())  
      .then(data => {
        setCountry(data.country)
        setClientIP(data.ip)
      })
  }, []);

  React.useEffect(() => {
    const checkCaptcha = async () => {
      const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
        clientIP, 
        action: "check"
      })
      if (res.status === 200) setShowCaptcha(false)
      if (res.status === 202) setShowCaptcha(true)
    }

    if(clientIP) checkCaptcha()
  }, [clientIP])

  const captchaRef = React.useRef(null)

  React.useEffect(() => {
    const isFormValid = Object.values(formValues).every(value => value)

    if (!isFormValid) return

    setIsFormValid(isFormValid)
  }, [formValues])

  const handleInputs = e => {
    const { name, value } = e.target  

    if (name === 'date') {
      setFormValues({
        ...formValues,
        day: value.slice(8),
        month: MONTHS[pageLang.toUpperCase()][parseInt(value.slice(5,7)) - 1]
      })
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      })
    }
  }

  const handleCaptcha = () => {
    const token = captchaRef.current.getValue()
    if (token) {
      setShowErrorCaptcha(false)
      return false
    }
    setShowErrorCaptcha(true)
    return true
  }

  const handleCheckboxes = e => {
    const { name, checked } = e.target

    setCheckboxesValues({
      ...checkboxesValues,
      [name]: checked,
    })
  }

  const resetForm = () => {
    setCheckboxesValues(checkboxesInitialValues)
    setFormValues(formInitialValues)
    setIsFormValid(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      if (useCaptcha) {
        if (showCaptcha) {
          const token = captchaRef.current.getValue();
          if (token) {
            try {
              const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
                clientIP, 
                action: "submit",
                captchaUrl: `https://www.google.com/recaptcha/api/siteverify?secret=6Ld2rL0kAAAAAMu9_rMrS0HMhPpbzlO9L11OpDSS&response=${token}`
              })
              if (res.status === 200) setShowCaptcha(false)
              if (res.status === 202) setShowCaptcha(true)
            } catch {
              setShowErrorCaptcha(true)
              return
            }
          }
          setShowErrorCaptcha(true)
          return
        }

        const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
          clientIP, 
          action: "submit"
        })
        if (res.status === 200) setShowCaptcha(false)
        if (res.status === 202) setShowCaptcha(true)
      }

      const res = await MailingService.sendMail(
        { ...formValues, ...checkboxesValues, country, contactPerson },
        {...formValues, ...checkboxesValues}
      )
      if (res) {
        setMessage(successMessage)
        resetForm()
      } else {
        throw new Error()
      }
    } catch (e) {
      setMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const dateToString = date => date.toISOString().slice(0, 10);

  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const today = dateToString(date);
  date.setMonth(date.getMonth() + 6);
  const maxDate = dateToString(date);

  return (
    <>
      {isActive && (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputs}
            placeholder={mainInputPlaceholder}
            className={styles.Input}
            required
          />
          <SelectInput
            className={styles.Input}
            value={formValues.companySize}
            onChange={handleInputs}
            name="companySize"
            optionsValue={companySizes.map(({ name }) => name)}
            placeholder={selectInputPlaceholder}
            required
          />
          {dateInputsTitle && (
            <span className={styles.InputLabel}>{dateInputsTitle}</span>
          )}
          <input 
            className={styles.Input} 
            onChange={handleInputs} 
            type="date" 
            name="date" 
            min={today} 
            max={maxDate}
            required
          />
          <SelectInput
              className={styles.Input}
              value={formValues.hour}
              onChange={handleInputs}
              name="hour"
              optionsValue={getHoursRange(
                minMeetingHour,
                maxMeetingHour,
                pageLang
              )}
              placeholder={dateInputHourPlaceholder}
              required
            />
          {isFormValid && (
            <>
              <Checkbox
                isChecked={checkboxesValues.privacyAgreement}
                name="privacyAgreement"
                onChange={handleCheckboxes}
                required
                text={privacyPolicyAgreementCheckbox}
              />
              <Checkbox
                isChecked={checkboxesValues.marketingAgreement}
                name="marketingAgreement"
                onChange={handleCheckboxes}
                text={marketingAgreementCheckbox}
              />
            </>
          )}
          {useCaptcha && showCaptcha && (
            <div className={styles.Captcha}>
              <ReCAPTCHA 
                sitekey="6Ld2rL0kAAAAAPMldKss1yYJmpWfSil0UPlBBlqh"
                onChange={handleCaptcha}
                ref={captchaRef}
              />
              {showErrorCaptcha && (
                <p className={styles.CaptchaError}>{captchaErrorMessage}</p>
              )}
            </div>
          )}     
          {message && (
            <p className={
              message === successMessage ?
               styles.successMessage : 
               styles.errorMessage
            }>
              {message}
            </p>
          )}  
          {loading ? (
            <div className={styles.Loader}>
              <Loader type="Oval" color="#323b4c" height={50} width={50} />
              <p>{sendingMessage}</p>
            </div>
          ) : (
            <Button {...button} />
          )}
        </form>
      )}
    </>
  )
}

export default DateForm

DateForm.propTypes = {
  button: PropTypes.object,
  dateInputDayPlaceholder: PropTypes.string,
  dateInputHourPlaceholder: PropTypes.string,
  dateInputMonthPlaceholder: PropTypes.string,
  isActive: PropTypes.bool,
  mainInputPlaceholder: PropTypes.string,
  selectInputPlaceholder: PropTypes.string,
}
