import React from "react"
import PropTypes from "prop-types"

import { TwoColumnsListModel } from "../../models/TwoColumnsList.model"

import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"

import styles from "./TwoColumnsListView.module.scss"

const TwoColumnsListView = ({ twoColumnsListData }) => {
  if (!twoColumnsListData) return null

  const {
    backgroundColor,
    containerPadding,
    leftList,
    rightList,
    title,
    titleSize,
  } = new TwoColumnsListModel(twoColumnsListData)

  return (
    <Section backgroundColor={backgroundColor}>
      <SectionTitle
        title={title}
        titleSize={titleSize}
        className={styles.Title}
      />
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <div
          dangerouslySetInnerHTML={{ __html: leftList }}
          className={styles.ListContainer}
        />
        <div
          dangerouslySetInnerHTML={{ __html: rightList }}
          className={styles.ListContainer}
        />
      </Container>
    </Section>
  )
}

export default TwoColumnsListView

TwoColumnsListView.propTypes = {
  twoColumnsListData: PropTypes.object.isRequired,
}
