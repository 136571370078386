import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import Loader from "react-loader-spinner"
import ReCAPTCHA from "react-google-recaptcha"

import MailingService from '../../../service/MailingService'

import Button from "../Button/Button"
import Checkbox from "../Checkbox/Checkbox"
import SelectInput from "../SelectInput/SelectInput"

import styles from "./ContactForm.module.scss"

const formInitialValues = {
  email: "",
  companySize: "",
  message: "",
}

const checkboxesInitialValues = {
  privacyAgreement: false,
  marketingAgreement: false,
}

const ContactForm = ({
  button,
  companySizes,
  isActive,
  mainInputPlaceholder,
  marketingAgreementCheckbox,
  privacyPolicyAgreementCheckbox,
  selectInputPlaceholder,
  textAreaInputPlaceholder,
  captchaErrorMessage,
  useCaptcha,
  contactPerson,
  sendingMessage,
  successMessage,
  errorMessage
}) => {
  const [showErrorCaptcha, setShowErrorCaptcha] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [checkboxesValues, setCheckboxesValues] = useState(
    checkboxesInitialValues
  )
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientIP, setClientIP] = useState('')
  const [country, setCountry] = useState('PL')
  const [message, setMessage] = useState('')

  React.useEffect(() => {
    fetch('https://ipinfo.io/json?token=ea4d0cf5569d7d')
      .then(res => res.json())  
      .then(data => {
        setCountry(data.country)
        setClientIP(data.ip)
      })
  }, []);

  React.useEffect(() => {
    const checkCaptcha = async () => {
      const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
        clientIP, 
        action: "check"
      })
      if (res.status === 200) setShowCaptcha(false)
      if (res.status === 202) setShowCaptcha(true)
    }

    if(clientIP) checkCaptcha()
  }, [clientIP]);

  const captchaRef = React.useRef(null)

  const handleInputs = e => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleCheckboxes = e => {
    const { name, checked } = e.target

    setCheckboxesValues({
      ...checkboxesValues,
      [name]: checked,
    })
  }

  const handleCaptcha = () => {
    const token = captchaRef.current.getValue()
    if (token) {
      setShowErrorCaptcha(false)
      return false
    }
    setShowErrorCaptcha(true)
    return true
  }

  const resetForm = () => {
    setCheckboxesValues(checkboxesInitialValues)
    setFormValues(formInitialValues)
    setIsFormValid(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      if (useCaptcha) {
        if (showCaptcha) {
          const token = captchaRef.current.getValue();
          if (token) {
            try {
              const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
                clientIP, 
                action: "submit",
                captchaUrl: `https://www.google.com/recaptcha/api/siteverify?secret=6Ld2rL0kAAAAAMu9_rMrS0HMhPpbzlO9L11OpDSS&response=${token}`
              })
              if (res.status === 200) setShowCaptcha(false)
              if (res.status === 202) setShowCaptcha(true)
            } catch {
              setShowErrorCaptcha(true)
              return
            }
          }
          setShowErrorCaptcha(true)
          return
        }

        const res = await axios.post('https://swh-p-spambotprot-fap.azurewebsites.net/api/SpamBotProtection', {
          clientIP, 
          action: "submit"
        })
        if (res.status === 200) setShowCaptcha(false)
        if (res.status === 202) setShowCaptcha(true)
      }      

      const res = await MailingService.sendMail(
        { ...formValues, ...checkboxesValues, country, contactPerson },
        {...formValues, ...checkboxesValues}
      )
      if (res) {
        setMessage(successMessage)
        resetForm()
      } else {
        throw new Error()
      }
    } catch (e) {
      setMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const isFormValid = Object.values(formValues).every(value => value)

    if (!isFormValid) return

    setIsFormValid(isFormValid)
  }, [formValues])

  return (
    <>
      {isActive && (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputs}
            placeholder={mainInputPlaceholder}
            className={styles.Input}
            required
          />
          <SelectInput
            className={styles.Input}
            value={formValues.companySize}
            onChange={handleInputs}
            name="companySize"
            optionsValue={companySizes.map(({ name }) => name)}
            placeholder={selectInputPlaceholder}
            required
          />
          <textarea
            name="message"
            value={formValues.message}
            onChange={handleInputs}
            placeholder={textAreaInputPlaceholder}
            className={`${styles.Input} ${styles.TextArea}`}
            required
          />
          {isFormValid && (
            <>
              <Checkbox
                isChecked={checkboxesValues.privacyAgreement}
                name="privacyAgreement"
                onChange={handleCheckboxes}
                required
                text={privacyPolicyAgreementCheckbox}
              />
              <Checkbox
                isChecked={checkboxesValues.marketingAgreement}
                name="marketingAgreement"
                onChange={handleCheckboxes}
                text={marketingAgreementCheckbox}
              />
            </>
          )}
          {useCaptcha && showCaptcha && (
            <div className={styles.Captcha}>
              <ReCAPTCHA 
                sitekey="6Ld2rL0kAAAAAPMldKss1yYJmpWfSil0UPlBBlqh"
                onChange={handleCaptcha}
                ref={captchaRef}
              />
              {showErrorCaptcha && (
                <p className={styles.CaptchaError}>{captchaErrorMessage}</p>
              )}
            </div>
          )}         
          {message && (
            <p className={
              message === successMessage ?
               styles.successMessage : 
               styles.errorMessage
            }>
              {message}
            </p>
          )}       
          {loading ? (
            <div className={styles.Loader}>
              <Loader type="Oval" color="#323b4c" height={50} width={50} />
              <p>{sendingMessage}</p>
            </div>
          ) : (
            <Button {...button} />
          )}
        </form>
      )}
    </>
  )
}

export default ContactForm

ContactForm.propTypes = {
  button: PropTypes.object,
  isActive: PropTypes.bool,
  mainInputPlaceholder: PropTypes.string,
  selectInputPlaceholder: PropTypes.string,
  textAreaInputPlaceholder: PropTypes.string,
}
