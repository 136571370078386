import React from "react"
import PropTypes from "prop-types"

const LinkedSlide = ({ children, slug }) => {
  if (slug) {
    return <a href={slug} target="_blank" rel="noopener noreferrer">{children}</a>
  }
  return <>{children}</>
}

export default LinkedSlide

LinkedSlide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  slug: PropTypes.string,
}
