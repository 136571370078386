import React from "react"
import SmallTextInput from "../SmallTextInput/SmallTextInput"
import TextInput from "../TextInput/TextInput"

import styles from "./DownloadItem.module.scss"

const DownloadItem = props => {
  const { date, description, button, icon, fileUrl } = props

  return (
    <div className={styles.DownloadItemContainer}>
      <img src={icon} className={styles.FileIcon} alt="File icon" />
      <div className={styles.DescriptionContainer}>
        <SmallTextInput>{date}</SmallTextInput>
        <TextInput styleProps={{ margin: "4px 0 20px", maxWidth: "420px" }}>
          {description}
        </TextInput>
        <a rel="noopener noreferrer" href={fileUrl} download target="_blank">
          <button
            className={
              button.buttonStyle === "Secondary"
                ? styles.ButtonStyleSecondary
                : styles.ButtonStylePrimary
            }
          >
            {button.name}
          </button>
        </a>
      </div>
    </div>
  )
}

export default DownloadItem
