import { get } from "lodash"
import { ButtonModel } from "./Button.model"
import { NavigatorModel } from "./Navigator.model"

export class HeaderModel {
  constructor(data) {
    this.data = data
  }

  get backgroundImage() {
    return get(this.data, "elements.background_image.value", [])
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, name, size, slug } = new ButtonModel(buttonData)

    return {
      color,
      name,
      size,
      slug,
    }
  }

  get imageMobilePosition() {
    return get(
      this.data,
      "elements.image_mobile_position__position.value[0].name",
      ""
    )
  }

  get mobileBackgroundColor() {
    return get(this.data, "elements.mobile_background_color.value", "")
  }

  get scrollToButton() {
    const navigatorData = get(
      this.data,
      "elements.scroll_to_button.linked_items[0]",
      {}
    )

    const { title, to } = new NavigatorModel(navigatorData)

    return {
      title,
      to,
    }
  }

  get sideImage() {
    return get(this.data, "elements.side_image.value[0].url", "")
  }

  get text() {
    return get(this.data, "elements.text.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }
}
