import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { OneColumnWithSliderModel } from "../../models/OneColumnWithSlider"

import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"
import LinkedSlide from "../../components/containers/LinkedSlide/LinkedSlide"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slide from "../../components/containers/Slide/Slide"
import Slider from "../../components/containers/Slider/Slider"

import styles from "./OneColumnWithSliderView.module.scss"

const OneColumnWithSliderView = ({ oneColumnWithSliderData }) => {
  const [windowWidth, setWindowWidth] = useState(undefined)
  const isMobile = windowWidth <= 1024

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  if (!oneColumnWithSliderData) return null

  const {
    backgroundColor,
    button,
    containerPadding,
    description,
    slideType,
    slides,
    sliderOnlyOnMobile,
    title,
    titleSize,
  } = new OneColumnWithSliderModel(oneColumnWithSliderData)

  const slidesComponent = slides.map(slide => (
    <LinkedSlide key={slide.id} slug={slide.slug}>
      <Slide {...slide} slideType={slideType} />
    </LinkedSlide>
  ))

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {sliderOnlyOnMobile ? (
          <>
            {isMobile ? (
              <Slider>{slidesComponent}</Slider>
            ) : (
              <div className={styles.GridContainer}>{slidesComponent}</div>
            )}
          </>
        ) : (
          <Slider>{slidesComponent}</Slider>
        )}
        {description && <p className={styles.Description}>{description}</p>}
        {button && <Button className={styles.Button} {...button} />}
      </Container>
    </Section>
  )
}

export default OneColumnWithSliderView

OneColumnWithSliderView.propTypes = {
  oneColumnWithSliderData: PropTypes.object.isRequired,
}
