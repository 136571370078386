import React from "react"
import PropTypes from "prop-types"

import { NumbersSectionModel } from "../../models/NumbersSection.model"

import Container from "../../components/containers/Container/Container"
import LinkedSlide from "../../components/containers/LinkedSlide/LinkedSlide"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slide from "../../components/containers/Slide/Slide"
import Slider from '../../components/containers/Slider/Slider'

import styles from "./NumbersSectionView.module.scss"

const NumbersSectionView = ({ numbersSectionData }) => {
  if (!numbersSectionData) return null

  const {
    backgroundColor,
    backgroundIcons,
    backgroundText,
    containerPadding,
    slideType,
    slides,
    title,
    titleSize,
  } = new NumbersSectionModel(numbersSectionData)

  return (
    <Section backgroundColor={backgroundColor}>
      <Container additionalClass={containerPadding}>
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        <div className={styles.InnerContainer}>
          <p className={styles.BackgroundText}>{backgroundText}</p>
          {backgroundIcons.map(icon => (
            <div
            key={icon.url}
            className={styles.BackgroundIcon}
            style={{ backgroundImage: `url(${icon.url})` }}
            />
          ))}
            <Slider className={styles.Slider}>
              {slides.map(slide => (
                <LinkedSlide key={slide.id} slug={slide.slug}>
                  <Slide {...slide} slideType={slideType} />
                </LinkedSlide>
              ))}
            </Slider>
        </div>
      </Container>
    </Section>
  )
}

export default NumbersSectionView

NumbersSectionView.propTypes = {
  numbersSectionData: PropTypes.object.isRequired,
}
