import { get } from "lodash"
import { ButtonModel } from "./Button.model"
import { SlideModel } from "./Slide.model"

export class HowItWorksModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, name, size, slug } = new ButtonModel(buttonData)

    return {
      color,
      name,
      size,
      slug,
    }
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get description() {
    return get(this.data, "elements.description.value", "")
  }

  get slides() {
    const slidesData = get(this.data, "elements.slides.linked_items", [])

    return slidesData.map(slide => new SlideModel(slide).getSlide())
  }

  get slideType() {
    return get(this.data, "elements.slide_type__type.value[0].name", "")
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }
}
