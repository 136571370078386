import React from "react"
import PropTypes from "prop-types"
import { ThreeFourColumnSectionModel } from "../../models/ThreeFourColumnSectionModel"
import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import ColumnCard from "../../components/containers/ColumnCard/ColumnCard"
import Button from "../../components/blocks/Button/Button"

import styles from './ThreeFourColumnSectionView.module.scss'

const ThreeFourColumnSectionView = ({ threefourColumnsData }) => {
  if (!threefourColumnsData) return null

  const {
    backgroundColor,
    containerPadding,
    cards,
    title,
    titleSize,
    subtitle,
    button
  } = new ThreeFourColumnSectionModel(threefourColumnsData)

  const numberOfColumns = cards.length === 4 ? 'FourColumnsWrapper' : 'ThreeColumnsWrapper'

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {subtitle && <p className={styles.SubTitle}>{subtitle}</p>}
        <div className={styles[numberOfColumns]}>
          {cards.map(card => (
            <ColumnCard {...card} />
          ))}
        </div>
        {!!button.name && <Button {...button} className={styles.Button} />}
      </Container>
    </Section>
  )
}

export default ThreeFourColumnSectionView

ThreeFourColumnSectionView.propTypes = {
  threefourColumnsData: PropTypes.object.isRequired
}