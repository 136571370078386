import React from "react"
import styles from "./SmallTextInput.module.scss"

const SmallTextInput = ({ children, styleProps }) => (
  <h3 className={styles.SmallTextInput} style={styleProps ? styleProps : null}>
    {children}
  </h3>
)

export default SmallTextInput
