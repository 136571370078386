import { get } from "lodash"

export class LandingPageParagraphSectionModel{
    constructor(data){
        this.data = data
    }
    get title(){
        return get(this.data, "elements.title.value", "" )
    }
    get text(){
        return get(this.data, "elements.text.value", "" )
    }
    get image(){
        return get(this.data, "elements.image.value[0]", "" )
    }
}