import { get } from 'lodash';

export class LandingPageTwoColumnsSectionModel {
  constructor(data) {
    this.data = data;
  }

  get image() {
    return get(this.data, 'elements.image.value[0]')
  }

  get text() {
    return get(this.data, 'elements.text.resolvedData.html', '');
  }
}