import { get } from "lodash"

export class LandingPageTestimonialModel {
  constructor(data) {
    this.data = data
  }

  get icons() {
    return get(this.data, "elements.icons.value", [])
  }

  get image() {
    return get(this.data, "elements.image.value[0]", "")
  }

  get text() {
    return get(this.data, "elements.text.resolvedData.html", "")
  }
}
