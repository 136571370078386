import React from "react"
import PropTypes from "prop-types"

import styles from "./Checkbox.module.scss"

const Checkbox = ({ isChecked, name, onChange, required = false, text }) => {
  const checkedClass = isChecked ? styles.Checked : ""

  return (
    <label className={styles.CheckboxWrapper}>
      <div className={`${styles.Checkbox} ${checkedClass}`}>
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          required={required}
          checked={isChecked}
        />
      </div>
      <p>{text}</p>
    </label>
  )
}

export default Checkbox

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  text: PropTypes.string.isRequired,
}
