import { get } from "lodash"
import { ButtonModel } from "./Button.model"
import { CardModel } from './Card.model'

export class ThreeFourColumnSectionModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get cards() {
    const cardsData = get(this.data, "elements.cards.linked_items", [])

    return cardsData.map(card => new CardModel(card).getCard())
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }

  get button() {
    const buttonData = get(this.data, "elements.button.linked_items[0]", {})

    const { color, name, size, slug } = new ButtonModel(buttonData)

    return {
      color,
      name,
      size,
      slug,
    }
  }
}
