import React from "react";

import Button from "../Button/Button"
import SectionTitle from "../SectionTitle/SectionTitle";

import styles from "./ColumnWithImageComponent.module.scss"

const ColummWithImageComponent = ({
    titleSize,
    button,
    imageUrl, 
    subtitle, 
    title
}) => {
    return (
        <div className={styles.Container}>
            <img
                src={imageUrl}
                className={`${styles.Image}`}
                alt=""
            />
            <SectionTitle
                className={styles.Title}
                title={title}
                titleSize={titleSize}
            />
            
                {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
            <Button {...button} />
        </div>
    )
}

export default ColummWithImageComponent;