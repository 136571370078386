import { get } from "lodash"
import { SlideModel } from "./Slide.model"

export class CustomersReferencesModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get sideIcons() {
    return get(this.data, "elements.side_icons.value", [])
  }

  get showSideIconsOnMobile() {
    return get(
      this.data,
      "elements.show_side_icons_on_mobile.value[0].name",
      ""
    )
  }

  get slides() {
    const slidesData = get(this.data, "elements.slides.linked_items", [])

    return slidesData.map(slide => new SlideModel(slide))
  }
}
