import { get } from 'lodash';

export class ContactPersonModel {
  constructor(data) {
    this.data = data;
  }

  get email() {
    return get(this.data, 'elements.email.value', '');
  }

  get image() {
    return get(this.data, 'elements.image.value[0].url', '');
  }
  
  get alt(){
    return get(this.data, 'elements.name_and_surname.value', '');
  }

  get nameAndSurname() {
    return get(this.data, 'elements.name_and_surname.value', '');
  }

  get phoneNumber() {
    return get(this.data, 'elements.phone_number.value', '');
  }
}