import { get } from "lodash"

export class LandingPageNavigatorModel {
  constructor(data) {
    this.data = data
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get navigationText() {
    return get(this.data, "elements.navigation_text.value", "")
  }

  get navigators() {
    const navigators = get(this.data, "elements.navigators.linked_items", [])

    return navigators.map(navigator => ({
      backgroundColor: get(
        navigator,
        "elements.background_color.value[0].name",
        ""
      ),
      sectionId: get(navigator, "elements.section_id.value", ""),
      title: get(navigator, "elements.title.value", ""),
    }))
  }
}
