import { get } from "lodash"

export class SlideModel {
  constructor(data) {
    this.data = data
  }

  get id() {
    return get(this.data, "system.id", "")
  }

  get alt() {
    return get(this.data, "elements.image.value[0].description", "")
  }

  get image() {
    return get(this.data, "elements.image.value[0].url", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get subtitle() {
    return get(this.data, "elements.subtitle.value", "")
  }

  get description() {
    return get(this.data, "elements.description.value", "")
  }

  get slideButtonText() {
    return get(this.data, "elements.slide_button_text.value", "")
  }

  get secondaryImage() {
    return get(this.data, "elements.secondary_image.value[0].url", "")
  }

  get slug() {
    return get(this.data, "elements.slug_to_page.value", "")
  }

  getSlide() {
    return {
      id: this.id,
      alt: this.alt,
      image: this.image,
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      slideButtonText: this.slideButtonText,
      secondaryImage: this.secondaryImage,
      slug: this.slug,
    }
  }
}
