import { get } from "lodash"
import { SlideModel } from "./Slide.model"

export class OurTeamModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(
      this.data,
      "elements.section_options__background_color.value[0].name",
      ""
    )
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(
      this.data,
      "elements.section_options__title_size.value[0].name",
      ""
    )
  }

  get slides() {
    const slidesData = get(this.data, "elements.slides.linked_items", [])

    return slidesData.map(data => new SlideModel(data).getSlide())
  }

  get slideType() {
    return get(this.data, "elements.slide_type__type.value[0].name", "")
  }

  get buttons() {
    let left = get(this.data, "elements.left_arrow.value[0].url", '')
    let right = get(this.data, "elements.right_arrow.value[0].url", '')
    return [left, right];
  }
}
