import React from "react"
import PropTypes from "prop-types"

import styles from "./NumbersSlide.module.scss"

const NumbersSlide = ({ image, title, subtitle }) => {
  return (
    <div className={styles.Slide}>
      <p className={styles.SlideTitle}>{title}</p>
      <div className={styles.SlideImage} style={{ backgroundImage: `url(${image})` }} />
      <p className={styles.SlideSubtitle}>{subtitle}</p>
    </div>
  )
}

export default NumbersSlide

NumbersSlide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}
