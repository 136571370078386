import React from "react";
import PropTypes from "prop-types"

import {LandingPageParagraphSectionModel} from "../../../../models/LandingPageParagraphSection.model"

import styles from "./LandingPageParagraphSection.module.scss"

const LandingPageParagraphSection = ({paragraphSectionData}) =>{
    const {image, title, text } = new LandingPageParagraphSectionModel(paragraphSectionData);
    return (
        <div>
            <img
                src={image.url}
                alt={image.description || ""}
                className={styles.Image}
            /> 
            <p>
                <strong>{title}</strong>  
            </p> 
            <p>{text}</p>
        </div>
    )
}

export default LandingPageParagraphSection;

LandingPageParagraphSection.propTypes = {
    paragraphSectionData: PropTypes.object.isRequired,
}