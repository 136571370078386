import React from "react"
import PropTypes from "prop-types"

import { TwoColumnsModel } from "../../models/TwoColumns.model"

import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Section from "../../components/containers/Section/Section"

import styles from "./TwoColumnsView.module.scss"

const TwoColumnsView = ({ twoColumnsData }) => {
  if (!twoColumnsData) return null

  const {
    backgroundColor,
    containerPadding,
    button,
    image,
    imageMobilePosition,
    imageSide,
    textBig,
    textSmall,
    title,
    titleSize,
  } = new TwoColumnsModel(twoColumnsData)

  const imagePositionClass = imageMobilePosition
    ? styles[imageMobilePosition]
    : styles.Left
  const imageSideClass = imageSide ? styles[imageSide] : styles.Top

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={`${styles.SectionWrapper} ${imagePositionClass}`}
        additionalClass={containerPadding}
      >
        <img
          src={image}
          className={`${styles.Image} ${imageSideClass}`}
          alt=""
        />
        <div className={styles.Content}>
          <SectionTitle
            className={styles.Title}
            title={title}
            titleSize={titleSize}
          />
          {textBig && <p className={styles.TextBig}>{textBig}</p>}
          {textSmall && <p className={styles.TextSmall}>{textSmall}</p>}
          {button.name && <Button {...button} />}
        </div>
      </Container>
    </Section>
  )
}

export default TwoColumnsView

TwoColumnsView.propTypes = {
  twoColumnsData: PropTypes.object.isRequired,
}
