import { get } from "lodash"

export class NavigatorModel {
  constructor(data) {
    this.data = data
  }

  get title() {
    return get(this.data, "elements.title.value", "")
  }

  get to() {
    return get(this.data, "elements.to.value", "")
  }
}
