import React from "react"
import PropTypes from "prop-types"

import styles from "./PersonSlide.module.scss"

const PersonSlide = ({ alt, image, secondaryImage, subtitle, title }) => {
  return (
    <div className={styles.Slide}>
      <div className={styles.Images}>
        <img src={image} className={styles.SlideImage} alt={alt || ""} />
        <img src={secondaryImage} className={styles.SlideSecondaryImage} alt="" />
      </div>
      <div className={styles.Content}>
        <p className={styles.SlideTitle}>{title}</p>
        <p className={styles.SlideSubtitle}>{subtitle}</p>
      </div>
    </div>
  )
}

export default PersonSlide

PersonSlide.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  secondaryImage: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
