import React from "react"
import { useIsMobile } from "../../hooks/useIsMobile"

import { HeaderModel } from "../../models/Header.model"

import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"

import arrowDown from "../../images/arrow_down.svg"

import styles from "./HeaderView.module.scss"

const HeaderView = ({ headerData }) => {
  const { isMobile, isTablet, isDesktop } = useIsMobile()

  if (!headerData) return null

  const {
    backgroundImage,
    button,
    imageMobilePosition,
    mobileBackgroundColor,
    scrollToButton,
    sideImage,
    text,
    title,
  } = new HeaderModel(headerData)

  return (
    <div
      className={styles.Header}
      style={{
        backgroundImage: isMobile
          ? "none"
          : `url(${
              isTablet && backgroundImage[1]
                ? backgroundImage[1].url
                : backgroundImage[0].url
            })`,
      }}
    >
      {isMobile ? (
        <div className={styles.MobileContainer}>
          <div
            className={styles.MobileContentContainer}
            style={{ backgroundColor: mobileBackgroundColor || "" }}
          >
            <h1 className={styles.HeroTitle}>{title}</h1>
            <p className={styles.HeroDescription}>{text}</p>
            {button.name && <Button className={styles.Button} {...button}/>}
          </div>
          <img
            src={
              backgroundImage[2]
                ? backgroundImage[2].url
                : backgroundImage[0].url
            }
            alt=""
            className={`${styles.MobileImage} ${styles[imageMobilePosition]}`}
          />
        </div>
      ) : (
        <Container additionalClass="Large" className={styles.Container}>
          <div className={styles.HeroContent}>
            <h1 className={styles.HeroTitle}>{title}</h1>
            <p className={styles.HeroDescription}>{text}</p>
            {button.name && <Button className={styles.Button} {...button}/>}
            {scrollToButton.title && (
              <a href={`#${scrollToButton.to}`} className={styles.ScrollButton}>
                <span>{scrollToButton.title}</span>
                <img src={arrowDown} alt="Arrow down" />
              </a>
            )}
          </div>
          {!isDesktop && (
            <img src={sideImage} alt="" className={styles.SideImage} />
          )}
        </Container>
      )}
    </div>
  )
}

export default HeaderView
