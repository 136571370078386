import React from "react"
import PropTypes from "prop-types"

import { LandingPageNavigatorModel } from "../../../../models/LandingPageNavigator.model"

import LandingPageContainer from "../LandingPageContainer/LandingPageContainer"

import styles from "./LandingPageNavigator.module.scss"

const LandingPageNavigator = ({ navigatorData }) => {
  const { navigationText, navigators, title } = new LandingPageNavigatorModel(
    navigatorData
  )

  return (
    <section className={styles.Wrapper}>
      <LandingPageContainer>
        <h2 className={styles.Title}>{title}</h2>
        <ul>
          {navigators.map((navigator, index) => (
            <li key={navigator.sectionId} className={styles.ListItem}>
              <div className={styles.Content}>
                <div
                  style={{ backgroundColor: navigator.backgroundColor || "" }}
                  className={styles.Number}
                >
                  {index + 1}
                </div>
                <p>{navigator.title}</p>
              </div>
              <a href={`#${navigator.sectionId}`} className={styles.Navigator}>
                {navigationText}
              </a>
            </li>
          ))}
        </ul>
      </LandingPageContainer>
    </section>
  )
}

export default LandingPageNavigator

LandingPageNavigator.propTypes = {
  navigatorData: PropTypes.object.isRequired,
}
