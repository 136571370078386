import React from "react"
import PropTypes from "prop-types"

import styles from "./Container.module.scss"

const Container = ({ additionalClass, children, className = "" }) => {
  const paddingClass = styles[additionalClass] || ""

  return (
    <div className={`${className} ${styles.Container} ${paddingClass}`}>
      {children}
    </div>
  )
}

export default Container

Container.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}
