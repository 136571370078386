import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { get } from "lodash"

import LandingPageNavigation from "../components/blocks/LP/LandingPageNavigation/LandingPageNavigation"
import PageContentParser from "../components/containers/PageContentParser/PageContentParser"

const LandingPage = props => {
  const elements = get(
    props,
    "data.allKontentItemLandingPage.nodes[0].elements",
    {}
  )

  const metaTitle = get(elements, "seo__meta_title.value", "")
  const metaImage = get(elements, "seo__meta_image.value[0].url", "")
  const metaDescription = get(elements, "seo__meta_description.value", "")
  const ogType = get(elements, "seo__og_type.value[0].name", "")

  const logo = get(elements, "logo.value[0].url", "")
  const navigationData = get(elements, "navigation_bar.linked_items", [])
  const content = get(elements, "content.linked_items", [])

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content={ogType || "website"} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
      </Helmet>
      <LandingPageNavigation logo={logo} navigationData={navigationData} />
      <PageContentParser contentData={content} />
    </>
  )
}

export default LandingPage

export const query = graphql`
  query {
    allKontentItemLandingPage {
      nodes {
        elements {
          content {
            linked_items {
              ...LandingPageChapterHeaderFragment
              ...LandingPageHeaderFragment
              ...LandingPageNavigatorFragment
              ...LandingPageTestimonialFragment
              ...LandingPageSectionFragment
            }
          }
          logo {
            value {
              url
              name
            }
          }
          navigation_bar {
            linked_items {
              elements {
                title {
                  value
                }
                to {
                  value
                }
              }
            }
          }
          page_url {
            value
          }
          seo__meta_description {
            value
          }
          seo__meta_image {
            value {
              url
            }
          }
          seo__meta_title {
            value
          }
          seo__og_type {
            value {
              name
            }
          }
        }
      }
    }
  }
`
