import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"

import { CustomersReferencesModel } from "../../models/CustomersReferences.model"

import Container from "../../components/containers/Container/Container"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from "./CustomersReferencesView.module.scss"

const CustomersReferencesView = ({ customersReferencesData }) => {
  const [isMobile, setIsMobile] = useState(false)
  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 900)
  }
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
  }, [])

  if (!customersReferencesData) return null

  const {
    backgroundColor,
    containerPadding,
    title,
    titleSize,
    subtitle,
    sideIcons,
    showSideIconsOnMobile,
    slides,
  } = new CustomersReferencesModel(customersReferencesData)

  const settings = {
    appendDots: dots => <ul className={styles.SliderButtonsList}>{dots}</ul>,
    customPaging: i => {
      return (
        <button className={styles.SliderButton}>
          {slides[i].slideButtonText}
        </button>
      )
    },
    dotsClass: styles.SliderButtonsList,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const slidesComponent = slides.map(slide => (
    <div key={slide.id} className={styles.SlideContainer}>
      <div>
        <p className={styles.Description}> {slide.description}</p>
        <div className={styles.ContentContainer}>
          <div className={styles.InnerContainer}>
            <img src={slide.image} className={styles.Image} alt={slide.alt}/>
            <div className={styles.Wrapper}>
              <p className={styles.SlideTitle}>{slide.title}</p>
              <p className={styles.SlideSubtitle}>{slide.subtitle}</p>
            </div>
          </div>
          {slide.secondaryImage && (
            <img src={slide.secondaryImage} className={styles.SecondaryImage} alt=""/>
          )}
        </div>
      </div>
    </div>
  ))

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {subtitle && <p className={styles.SubTitle}>{subtitle}</p>}
        <div className={styles.SliderContainer}>
          {slidesComponent.length > 1 ? (
            <Slider {...settings}>{slidesComponent}</Slider>
          ) : (
            <>{slidesComponent[0]}</>
          )}
          {!isMobile && 
            sideIcons.map((icon, index) => (
              <img
                key={index}
                src={icon.url}
                className={`${index === 0 ? styles.LeftIcon : styles.RightIcon} ${showSideIconsOnMobile ? styles.showSideIconsOnMobile : ""}`}
                alt=""
              />
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default CustomersReferencesView

CustomersReferencesView.propTypes = {
  customersReferencesData: PropTypes.object.isRequired,
}
