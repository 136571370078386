import React from "react"
import PropTypes from "prop-types"

import { NavigatorModel } from "../../../../models/Navigator.model"

import styles from "./LandingPageNavigation.module.scss"

const LandingPageNavigation = ({ logo, navigationData }) => {
  const navItems = navigationData.map(
    navigator => new NavigatorModel(navigator)
  )

  return (
    <nav className={styles.Nav}>
      <img src={logo} className={styles.Logo} alt="Grow Uperion logo" />
      <ul className={styles.NavList}>
        {navItems.map(navItem => (
          <li key={navItem.to}>
            <a href={`#${navItem.to}`}>{navItem.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default LandingPageNavigation

LandingPageNavigation.propTypes = {
  logo: PropTypes.string.isRequired,
  navigationData: PropTypes.array.isRequired,
}
