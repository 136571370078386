import { get } from 'lodash'
import { AccordionModel } from './Accordion.model'
import { ButtonModel } from './Button.model'

export class AccordionViewModel {
  constructor(data) {
    this.data = data
  }

  get title() {
    return get(this.data, 'elements.title.value', '')
  }

  get subtitle() {
    return get(this.data, 'elements.subtitle.value', '')
  }

  get titleSize() {
    return get(this.data, 'elements.section_options__title_size.value[0].name', '')
  }

  get containerPadding() {
    return get(this.data, 'elements.section_options__padding.value[0].name', '')
  }

  get backgroundColor() {
    return get(this.data, 'elements.section_options__background_color.value[0].name', '')
  }

  get image() {
    return get(this.data, 'elements.image.value[0].url', '')
  }

  get imageMobilePosition() {
    return get(this.data, 'elements.image_mobile_position__position.value[0].name', '')
  }

  get imageSide() {
    return get(this.data, 'elements.image_side__side.value[0].name', '')
  }

  get button() {
    const buttonData = get(this.data, 'elements.button.linked_items[0]', {})
    const { color, image, name, size, slug } = new ButtonModel(buttonData)
    return { color, image, name, size, slug }
  }

  get accordionItems() {
    const accordionItems = get(this.data, 'elements.accordion_items.linked_items', [])
    const accordionData = accordionItems.map((item) => new AccordionModel(item))
    return accordionData
  }
}