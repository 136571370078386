import React from "react"
import PropTypes from "prop-types"

import styles from "./ColumnCard.module.scss"

const ColumnCard = ({ image, title, subtitle, description }) => {
  return (
    <div className={styles.Card}>
      {image ? (
        <>
          <img className={styles.Image} src={image} alt={title}/>
          <div className={styles.DescriptionWrapper}>
            {title && <p className={styles.Title}>{title}</p>}
            {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
            {description && <p className={styles.Description}>{description}</p>}
          </div>
        </>
      ) : <p className={styles.TitleBig}>{title}</p>}
    </div>
  )
}

export default ColumnCard

ColumnCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
}