import { get } from "lodash"
import { ColumnGamificationSectionModel } from "./ColumnGamificationSection.model"

export class TwoColumnsWithTitleModel{
    constructor(data) {
        this.data = data
    }
    get text() {
        return get(this.data, "elements.text.value", "")
    }

    get title() {
        return get(this.data, "elements.title.value", "")
    }

    get titleSize() {
        return get(
            this.data,
            "elements.section_options__title_size.value[0].name",
            ""
        )
    }

    get backgroundColor() {
        return get(
            this.data,
            "elements.section_options__background_color.value.name",
            ""
        )
    }

    get containerPadding() {
        return get(this.data, "elements.section_options__padding.value[0].name", "")
    }

    get gamificationOnbordingColumn(){
        const gamificationOnbordingColumnData = get(
            this.data,
            "elements.gamification_onbording_column.linked_items[0]",
            {}
        )
        const { title, subtitle, imageUrl, button, titleSize, backgroundColor, containerPadding } = new ColumnGamificationSectionModel(gamificationOnbordingColumnData);

        return { 
            title, 
            subtitle, 
            imageUrl, 
            button,
            titleSize,
            backgroundColor,
            containerPadding
        }
    }

    get gamificationPlatformColumn(){
        const gamificationPlatformColumnData = get(
            this.data,
            "elements.gamification_platform_column.linked_items[0]",
            {}
        )
        const { 
            title, 
            subtitle, 
            imageUrl, 
            button, 
            titleSize, 
            backgroundColor, 
            containerPadding 
        } = new ColumnGamificationSectionModel(gamificationPlatformColumnData);

        return { 
            title, 
            subtitle, 
            imageUrl, 
            button,
            titleSize,
            backgroundColor,
            containerPadding
        
        }
    }
}