import { get } from "lodash"

export class TwoColumnsListModel {

  constructor(data) {
    this.data = data;
  }

  get backgroundColor() {
   return get(this.data, "elements.section_options__background_color.value[0].name", "")
  }

  get containerPadding() {
    return get(this.data, "elements.section_options__padding.value[0].name", "")
  }

  get leftList() {
    return get(this.data, "elements.left_list.resolvedData.html", "")
  }

  get rightList() {
    return get(this.data, "elements.right_list.resolvedData.html", "")
  }

  get title() {
     return get(this.data, "elements.title.value", "")
  }

  get titleSize() {
    return get(this.data, "elements.section_options__title_size.value[0].name", "")
  }
}