import React from "react"
import PropTypes from "prop-types"

import styles from "./OpenPositionSlide.module.scss"

const OpenPositionSlide = ({ alt, image, title }) => {

  return (
    <div className={styles.Slide}>
      <p className={styles.SlideTitle}>{title}</p>
      <img className={styles.SlideIamge} src={image} alt={alt || ""} />
    </div>
  )
}

export default OpenPositionSlide

OpenPositionSlide.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
