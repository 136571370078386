import React from "react"
import PropTypes from "prop-types"

import styles from "./LandingPageContainer.module.scss"

const LandingPageContainer = ({ children, className = '' }) => {
  return <div className={`${styles.Container} ${className}`}>{children}</div>
}

export default LandingPageContainer

LandingPageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}
