import React from "react"
import PropTypes from "prop-types"
import PlusMinusIcon from "../PlusMinusIcon/PlusMinusIcon"
import styles from './AccordionItem.module.scss'

const AccordionItem = ({ title, text }) => {
  const [isActive, setIsActive] = React.useState(false)
  const [contentDivHeight, setContentDivHeight] = React.useState('0px')
  const [contentDivMargin, setContentDivMargin] = React.useState('10px')
  const contentDiv = React.useRef(null)

  const toggleActive = () => {
    setIsActive(prevState => !prevState)
    setContentDivHeight(isActive ? '0px' : `${contentDiv.current.scrollHeight}px`)
    setContentDivMargin(isActive ? '10px' : `16px`)
  }

  return (
    <div className={styles.AccordionItem}>
      <div className={styles.ButtonLine}>
        <button
          onClick={toggleActive}
          className={styles.Button}
        >
          <div className={styles.Title}>{title}</div>
          <PlusMinusIcon active={isActive} />
        </button>
      </div>
      <div
        ref={contentDiv}
        className={styles.Text}
        style={{ maxHeight: `${contentDivHeight}`, margin: `${contentDivMargin} 0` }}
      >
        <div className={styles.TextElement}>{text}</div>
      </div>
    </div>
  )
}

export default AccordionItem

AccordionItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}
