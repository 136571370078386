import { get } from "lodash"

export class LandingPageImagesRowModel {
  constructor(data) {
    this.data = data
  }

  get images() {
    return get(this.data, "elements.images.value", [])
  }
}
