import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"

import styles from "./SliderWithButtons.module.scss"

const SliderWithButtons = ({ children, className = '', marginBottom, buttons = [] }) => {
  const slidesCount = children.length;
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [swipeWidth, setSwipeWidth] = useState(0)

  const handleTouchStart = e => {
    setIsMouseDown(true)
    setStartX(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = e => {
    setIsMouseDown(false)
    if (swipeWidth > 50) toRight();
    if (swipeWidth < -50) toLeft();
  }

  const handleTouchMove = e => {
    if(isMouseDown) setSwipeWidth(startX - e.targetTouches[0].clientX);
  }

  const handleWindowSizeChange = () => {
    if (sliderRef.current.scrollLeft + sliderRef.current.offsetWidth < sliderRef.current.scrollWidth) setIsLastSlide(false);
    if (sliderRef.current.scrollLeft + sliderRef.current.offsetWidth >= sliderRef.current.scrollWidth) setIsLastSlide(true);
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
  }, [])

  const sliderRef = useRef(null)

  const alignmentClass = slidesCount > 4 ? styles.FlexStart : '';

  const toRight = () => {
    const slideWidth = sliderRef.current.children[0].children[0].offsetWidth;
    const scrollWidth = slideWidth + 32;
    sliderRef.current.scrollLeft += scrollWidth
    if (sliderRef.current.scrollLeft + sliderRef.current.offsetWidth + scrollWidth >= sliderRef.current.scrollWidth) setIsLastSlide(true);
    setIsFirstSlide(false)
  }

  const toLeft = () => {
    const slideWidth = sliderRef.current.children[0].children[0].offsetWidth;
    const scrollWidth = slideWidth + 32;
    sliderRef.current.scrollLeft -= scrollWidth
    if (sliderRef.current.scrollLeft - scrollWidth <= 0) setIsFirstSlide(true);
    setIsLastSlide(false)
  }



  return (
  <div className={styles.SliderBox}>
    <div className={styles.LeftButton}>
      {!isFirstSlide && (
        <img src={buttons[0]} alt="to left" onClick={toLeft} />
      )}
    </div>

    <div
      className={`${styles.Slider} ${className}`}
      style={{ marginBottom: `${marginBottom}px`}}
      ref={sliderRef}

      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={`${styles.Box} ${alignmentClass}`}>
        {children}
      </div>
    </div>

    <div className={styles.RightButton}>
      {!isLastSlide && (
        <img src={buttons[1]} alt="to right" onClick={toRight} />
      )}
    </div>
  </div> 
  )
}

export default SliderWithButtons

SliderWithButtons.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
  buttons: PropTypes.arrayOf(PropTypes.string)
}