import React from "react"
import PropTypes from "prop-types"

import { LandingPageChapterHeaderModel } from "../../../../models/LandingPageChapterHeader.model"
import { DEFAULT_BACKGROUND_COLOR } from "../../../../utils/consts"

import LandingPageContainer from "../LandingPageContainer/LandingPageContainer"

import styles from "./LandingPageChapterHeader.module.scss"

const LandingPageChapterHeader = ({ chapterHeaderData }) => {
  const {
    backgroundColor,
    chapterName,
    chapterType,
    images,
    sectionId,
    subtitle,
    title,
  } = new LandingPageChapterHeaderModel(chapterHeaderData)

  let chapterImages = null

  switch (chapterType) {
    case "ChapterOne":
      chapterImages = (
        <img
          src={images[0].url}
          alt={images[0].description || ""}
          className={styles.ChapterOneImage}
        />
      )
      break
    case "ChapterTwo":
      chapterImages = (
        <>
          {images.length && (
            <div className={styles.ImagesContainer}>
              {images.map((image, index) => (
                <img
                  key={image.url}
                  src={image.url}
                  alt={image.description || ""}
                  className={
                    index === 0
                      ? styles.ChapterTwoImageLeft
                      : styles.ChapterTwoImageRight
                  }
                />
              ))}
            </div>
          )}
        </>
      )
      break
    case "ChapterThree":
      chapterImages = (
        <>
          {images.length && (
            <div className={styles.ImagesContainer}>
              {images.map((image, index) => (
                <img
                  key={image.url}
                  src={image.url}
                  alt={image.description || ""}
                  className={
                    index === 0
                      ? styles.ChapterThreeImageLeft
                      : styles.ChapterThreeImageRight
                  }
                />
              ))}
            </div>
          )}
        </>
      )
      break
    default:
      chapterImages = null
  }

  return (
    <section
      style={{
        backgroundColor: backgroundColor || DEFAULT_BACKGROUND_COLOR,
      }}
      className={styles.SectionWrapper}
      id={sectionId}
    >
      <LandingPageContainer className={styles.Container}>
        <h2 className={styles.Title}>{title}</h2>
        <p
          className={`${styles.ChapterName} ${
            backgroundColor !== "#004AB5" ? styles.Light : ""
          }`}
        >
          {chapterName}
        </p>
        {chapterImages}
        {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
      </LandingPageContainer>
    </section>
  )
}

export default LandingPageChapterHeader

LandingPageChapterHeader.propTypes = {
  chapterHeaderData: PropTypes.object.isRequired,
}
