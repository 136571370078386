import { get } from "lodash"

export class ButtonModel {
  constructor(data) {
    this.data = data
  }

  get color() {
    return get(
      this.data,
      "elements.button_color__button_color.value[0].name",
      ""
    )
  }

  get image() {
    return get(this.data, "elements.image.value[0].url")
  }

  get name() {
    return get(this.data, "elements.name.value", "")
  }

  get size() {
    return get(this.data, "elements.button_size__button_size.value[0].name", "")
  }

  get slug() {
    return get(this.data, "elements.slug_to_page.value", "")
  }
}
