import React from "react"
import PropTypes from "prop-types"

import styles from "./TeamSlide.module.scss"

const TeamSlide = ({ alt, image, subtitle, title, slug, description }) => {
  return (
    <div className={styles.SlideContainer}>
      <div className={styles.TeamSlideHeader}>
        <div>
          {title.split(" ").map(item => (
            <p key={item} className={styles.SlideTitle}>
              {item}
            </p>
          ))}
        </div>
        <div>
          {slug && (
            <a href={slug} target="_blank" rel="noopener noreferrer">
              <img src='https://cdn-icons-png.flaticon.com/512/174/174857.png' height='24px' alt={title}/>
            </a>
          )}
        </div>
      </div>
      <p className={styles.SlideSubtitle}>{subtitle}</p>
      <img src={image} className={styles.SlideImage} alt={alt || ''} />
      <p className={styles.SlideDescription}>{description}</p>
    </div>
  )
}

export default TeamSlide

TeamSlide.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
