import React from "react"
import PropTypes from "prop-types"

import { HowItWorksModel } from "../../models/HowItWorks.model"

import Button from "../../components/blocks/Button/Button"
import Container from "../../components/containers/Container/Container"
import LinkedSlide from "../../components/containers/LinkedSlide/LinkedSlide"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"
import Slider from "../../components/containers/Slider/Slider"
import Slide from "../../components/containers/Slide/Slide"

import styles from "./HowItWorksSectionView.module.scss"

const HowItWorksSectionView = ({ howItWorksData }) => {
  if (!howItWorksData) return null

  const {
    backgroundColor,
    containerPadding,
    description,
    slides,
    slideType,
    title,
    titleSize,
    subtitle,
    button,
  } = new HowItWorksModel(howItWorksData)

  return (
    <Section backgroundColor={backgroundColor}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <SectionTitle
          className={styles.Title}
          title={title}
          titleSize={titleSize}
        />
        {subtitle && <p className={styles.SubTitle}>{subtitle}</p>}
        <Slider>
          {slides.map(slide => (
            <LinkedSlide key={slide.id} slug={slide.slug}>
              <Slide {...slide} slideType={slideType} />
            </LinkedSlide>
          ))}
        </Slider>
        {description && <p className={styles.Description}>{description}</p>}
        <Button {...button} />
      </Container>
    </Section>
  )
}

export default HowItWorksSectionView

HowItWorksSectionView.propTypes = {
  howItWorksData: PropTypes.object.isRequired,
}
