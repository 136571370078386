import { get } from "lodash"
import { ButtonModel } from "./Button.model"

export class DownloadSectionModel {
  constructor(data) {
    this.data = data
  }

  get header() {
    return get(this.data, "elements.header.value", "")
  }

  get downloadItems() {   
    const downloadItemsData = get(this.data, "elements.download_items", [])

    const { downloadItems } = new DownloadItemsModel(downloadItemsData);

    return downloadItems
  }
}

export class DownloadItemsModel {
    constructor(data) {
        this.data = data
    }

    get downloadItems() {
        const arrayOfDownloadItems = [];
        const dataOfItems = get(this.data, "linked_items", []);
        dataOfItems.map(
            (item, i) => {
            const referenceItem = {
                date: get(item, "elements.date.value", ""),
                description: get(item, "elements.description.value", ""),
                button: new ButtonModel(get(item, "elements.download_button.linked_items[0]", "")),
                icon: get(item, "elements.icon.value[0].url", ""),
                fileUrl: get(item, "elements.download_file.value[0].url", '')
            }
            return (arrayOfDownloadItems[i] = referenceItem)
            })
        return arrayOfDownloadItems
    }
}
