import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { LandingPageSectionModel } from "../../../../models/LandingPageSection.model"

import { DEFAULT_BACKGROUND_COLOR } from "../../../../utils/consts"

import PageContentParser from "../../../containers/PageContentParser/PageContentParser"

import styles from "./LandingPageSection.module.scss"

const LandingPageSection = ({ sectionData }) => {
  const { backgroundColor, content, linkedItems } = new LandingPageSectionModel(
    sectionData
  )

  const contentWithIFrame = parse(content, {
    replace: domNode => {
      if (
        domNode.name === "p" &&
        domNode.attribs.type === "application/kenticocloud"
      ) {
        const codename = domNode.attribs["data-codename"]

        const linkedItem = linkedItems.filter(
          linkedItem => linkedItem.system && linkedItem.system.codename === codename
        )

        if (linkedItem.lenght === 0) return null

        return <PageContentParser contentData={linkedItem} />
      }

      const domNodeIsParagraph = domNode.name === "p"
      const domNodeHasDataToParse =
        domNode.children && domNode.children[0] && domNode.children[0].data
      const domNodeHasAnInframe = domNodeHasDataToParse
        ? domNode.children[0].data.indexOf("iframe") > -1
        : false

      if (domNodeIsParagraph && domNodeHasDataToParse && domNodeHasAnInframe) {
        const iframeDomNode = domNode.children[0].data
        const iframeUrl = iframeDomNode.match(/src="(.*?)"/)
          ? iframeDomNode.match(/src="(.*?)"/)[1]
          : ""
        return React.createElement(
          "iframe",
          {
            src: iframeUrl,
            width: "100%",
            height: "500px",
            allowFullScreen: true,
            frameBorder: 0,
          },
          "replaced"
        )
      }

      return domNode
    },
  })

  return (
    <section
      style={{ backgroundColor: backgroundColor || DEFAULT_BACKGROUND_COLOR }}
    >
      <div className={styles.Container}>{contentWithIFrame}</div>
    </section>
  )
}

export default LandingPageSection

LandingPageSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
}
