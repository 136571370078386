import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_BACKGROUND_COLOR } from '../../../utils/consts';

const Section = ({ backgroundColor = DEFAULT_BACKGROUND_COLOR, children, className = '' }) => {
  return (
    <section style={{backgroundColor: backgroundColor}} className={className}>
      {children}
    </section>
  )
}

export default Section;

Section.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
}