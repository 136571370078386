import { get } from "lodash"

export class LandingPageSectionModel {
  constructor(data) {
    this.data = data
  }

  get backgroundColor() {
    return get(this.data, "elements.background_color.value[0].name", "")
  }

  get content() {
    return get(this.data, "elements.content.resolvedData.html", "")
  }

  get linkedItems() {
    return get(this.data, 'elements.content.linked_items', [])
  }
}
