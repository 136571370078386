import React, { useState } from "react"
import PropTypes from "prop-types"

import { FORMS_TYPES } from "../../utils/consts"

import { ContactModel } from "../../models/Contact.model"

import ContactForm from "../../components/blocks/ContactForm/ContactForm"
import Container from "../../components/containers/Container/Container"
import DateForm from "../../components/blocks/DateForm/DateForm"
import Section from "../../components/containers/Section/Section"
import SectionTitle from "../../components/blocks/SectionTitle/SectionTitle"

import styles from "./ContactSectionView.module.scss"

const ContactSectionView = ({ contactData }) => {
  const [activeFormId, setActiveFormId] = useState(0)

  if (!contactData) return null

  const {
    backgroundColor,
    containerPadding,
    contactPerson,
    forms,
    image,
    subtitle,
    title,
    titleSize,
  } = new ContactModel(contactData)

  return (
    <Section backgroundColor={backgroundColor} className={styles.Section}>
      <Container
        className={styles.Container}
        additionalClass={containerPadding}
      >
        <div className={styles.ContentContainer}>
          <SectionTitle
            className={styles.Title}
            title={title}
            titleSize={titleSize}
          />
          <p className={styles.Subtitle}>{subtitle}</p>
          <div className={styles.ContactPersonContainer}>
            <img
              src={contactPerson.image}
              alt={contactPerson.alt}
              className={styles.Image}
            />
            <div className={styles.ContactPersonContent}>
              <p className={styles.Name}>{contactPerson.nameAndSurname}</p>
              <p className={styles.Phone}>{contactPerson.phoneNumber}</p>
              <p className={styles.Email}>{contactPerson.email}</p>
            </div>
          </div>
        </div>
        <div className={styles.FormContainer}>
          <div className={styles.SwitchContainer}>
            {forms.map((form, index) => (
              <button
                key={form.title}
                className={`${styles.Switch} ${
                  activeFormId === index ? styles.Active : ""
                }`}
                onClick={() => setActiveFormId(index)}
              >
                {form.title}
              </button>
            ))}
          </div>
          {forms.map((form, index) => {
            if (form.type === FORMS_TYPES.CONTACT) {
              return (
                <ContactForm
                  key={form.id}
                  isActive={activeFormId === index}
                  {...form}
                  contactPerson={contactPerson.nameAndSurname}
                />
              )
            } else {
              return (
                <DateForm
                  key={form.id}
                  isActive={activeFormId === index}
                  {...form}
                  contactPerson={contactPerson.nameAndSurname}
                />
              )
            }
          })}
        </div>
      </Container>
      <img src={image} className={styles.BottomImage} alt="" />
    </Section>
  )
}

export default ContactSectionView

ContactSectionView.propTypes = {
  contactData: PropTypes.object.isRequired,
}
