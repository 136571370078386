import React from "react"
import PropTypes from "prop-types"

import { SLIDES_TYPES } from "../../../utils/consts"

import IconSlide from "../IconSlide/IconSlide"
import NumbersSlide from "../NumbersSlide/NumbersSlide"
import OpenPositionSlide from "../OpenPositionSlide/OpenPositionSlide"
import PersonSlide from "../PersonSlide/PersonSlide"
import ServiceLineSlide from "../ServiceLineSlide/ServiceLineSlide"
import TeamSlide from "../TeamSlide/TeamSlide"

const Slide = ({ slideType, ...slide }) => {
  switch (slideType.toLowerCase()) {
    case SLIDES_TYPES.SERVICE_LINE:
      return <ServiceLineSlide {...slide} />

    case SLIDES_TYPES.PERSON:
      return <PersonSlide {...slide} />

    case SLIDES_TYPES.TEAM:
      return <TeamSlide {...slide} />

    case SLIDES_TYPES.ICON:
      return <IconSlide {...slide} />

    case SLIDES_TYPES.OPEN_POSITION:
      return <OpenPositionSlide {...slide} />

    case SLIDES_TYPES.NUMBER:
      return <NumbersSlide {...slide} />

    default:
      return null
  }
}

export default Slide

Slide.propTypes = {
  slideType: PropTypes.string.isRequired,
  slide: PropTypes.object.isRequired,
}
